import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  select: {
    color: 'var(--sec_shade_grey)',
    outline: 'none',
    borderRadius: '8px',
    padding: '-16px',
    boxShadow: 'none',
    '&:focus': {
      color: 'white',
      border: '0',
      outline: 'none',
      '& .MuiOutlinedInput-notchedOutline': {
        border: '0',
        outline: 'none',
      },
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      backgroundColor: `transparent`,
      color: 'white',
      border: "1px solid var(--pale_white)",
    },
  },
  label: {
    fontSize: "18px",
    paddingLeft: "10px",
    color: 'var(--sec_shade_grey)',
    '&.Mui-focused': {
    },
  },
  option: {
    color: 'var(--hero_heading)',
    margin: '-8px 0',
    padding: '16px 18px',
    width: '100%',
    fontSize: '18px',
    '&:focus': {
      color: 'var(--hero_heading)',
      outline: 'none',
    },
    '&:hover': {
      color: 'var(--hero_heading)',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      outline: 'none',
    },
  },
  selectedOption: {
    fontSize: '18px',
    paddingLeft: "10px",
    border: 'none',
    outline: 'none',
    '&:hover': {
      color: 'white',
    },
    '&:focus': {
      color: 'white',
      outline: 'none',
    },

  },
  filled: {
    color: 'var(--hero_heading)',
    backgroundColor: 'var(--light_teal)',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      outline: 'none',
    },
  },
}));


export default useStyles;