import styles from './PartnerForm.module.css';
import { IoClose } from 'react-icons/io5';
import React, { useEffect, useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select, makeStyles } from '@material-ui/core';
import { MdDeleteOutline, MdKeyboardArrowDown, MdOutlineDeleteOutline } from "react-icons/md";
import thankyou from '../../../../images/forms/partner/Thank_you.svg';
import axios from 'axios';
import useStyles from '../../../DropdownStyle/useStyles';


const PartnerForm = ({ handleClose }) => {
    const classes = useStyles();
    const [stage, setStage] = useState(1);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [emptyFields, setEmptyFields] = useState([]);
    const [isFocused, setIsFocused] = useState(false);
    const [states, setStates] = useState([]);

    const handleInputFocus = () => {
        setIsFocused(true);
    };
    const handleInputBlur = () => {
        setIsFocused(false);
    };
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        state: '',
        stateID: '',
    });

    const validateStage1 = () => {
        // const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return (
            // emailPattern.test(formData.email) &&
            formData.name !== '' &&
            formData.email !== '' &&
            formData.mobile !== '' &&
            formData.state !== ''
        );
    };

    const fetchStatesFromAPI = async () => {
        try {
            const response = await axios.get('/stateListWeb');
            const data = response.data.state;
            if (Array.isArray(data)) {
                setStates(data);
            } else {
                console.error('Invalid data structure received from the API');
            }
        } catch (error) {
            console.error('Error fetching states:', error.message);
        }
    };


    const handleInputChange = (e, inputName) => {
        let newValue = e.target.files ? e.target.files[0] : e.target.value;

        if (e.target.tagName === 'INPUT') {
            newValue = e.target.value;
        }

        setFormData((prevFormData) => ({
            ...prevFormData,
            [inputName]: newValue,
            ...(inputName === 'state' && {
                stateID: states.find((state) => state.state === newValue)?.stateID || '',
            }),
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsFormSubmitted(true); if (!validateStage1()) {
            setEmptyFields(["name", "email", "mobile", "state"]);
            return;
        }
        try {
            const response = await axios.post('/user_web', formData);
            // console.log('Response:', response.data);
            setStage(2);
        } catch (error) {
            // console.error('Error:', error);
            if (error.response && error.response.data && error.response.data.errors) {
                setEmptyFields(error.response.data.errors);
            }
        }
    };

    useEffect(() => {
        fetchStatesFromAPI();
    }, []);

    return (
        <>
            <div className={styles.container}>
                {
                    isFormSubmitted ? (
                        <>
                            <div className={styles.my_container}>
                                <div
                                    onClick={handleClose}
                                    className={styles.mui_close_button}
                                >
                                    <IoClose />
                                </div>
                                <div className={styles.thank_you_image}>
                                    <img src={thankyou} alt="thank_you" />

                                    <div className={styles.app_heading}>
                                        Application In Process
                                    </div>
                                    <div className={styles.app_sub_heading}>
                                        Your application is currently being processed. We're working diligently to complete it. Thank you for choosing us!
                                    </div>
                                </div>

                            </div>
                        </>
                    ) : (
                        <div className={styles.form_stage}>
                            <form onSubmit={handleSubmit}>

                                {stage === 1 && (
                                    <>
                                        {/* WELCOME TEXT */}
                                        <div className={styles.heading_section}>
                                            <div className={styles.welcome_text}>
                                                <div className={styles.welcome_text1}>
                                                    Register as Nxcar Partner 
                                                </div>
                                                <div className={styles.welcome_text2}>
                                                    Fill out your details
                                                </div>
                                            </div>
                                            <div
                                                onClick={handleClose}
                                                className={styles.mui_close_button}
                                            >
                                                <IoClose />
                                            </div>
                                        </div>

                                        <div className={styles.form}>
                                            <div className={styles.col}>
                                                <div className={styles.MUI_input}>
                                                    <input
                                                        className={formData.name.length > 0 ? styles.input_field1 : styles.input_field}
                                                        type="text"
                                                        value={formData.name}
                                                        onChange={(e) => handleInputChange(e, 'name')}
                                                        required
                                                    />
                                                    <div className={`${styles.float_label} ${formData.name && styles.labelActive}`}>
                                                        Enter Full Name <span style={{ color: `var(--red)` }}>*</span>
                                                    </div>
                                                    {localStorage.setItem('buyer_name', formData.name)}
                                                </div>
                                                {emptyFields.includes("name") && formData.name === "" && (
                                                    <p className={styles.error}>Full Name is required</p>
                                                )}

                                                <div className={styles.MUI_input}>
                                                    <input
                                                        className={formData.email.length > 0 ? styles.input_field1 : styles.input_field}
                                                        type="email"
                                                        value={formData.email}
                                                        onChange={(e) => handleInputChange(e, 'email')}
                                                        required
                                                    />
                                                    <div className={`${styles.float_label} ${formData.email && styles.labelActive}`}>
                                                        Enter Email
                                                    </div>
                                                </div>
                                                {emptyFields.includes("email") && formData.email === "" && (
                                                    <p className={styles.error}>Email is required</p>
                                                )}
                                                {formData.email && !(emailRegex).test(formData.email) && (
                                                    <p className={styles.error}>
                                                        Please provide a valid email
                                                    </p>
                                                )}

                                                {/* MOBILE NUMBER */}
                                                <div className={styles.MUI_input}>
                                                    <div className={styles.phoneNumberDisplay}>
                                                        +91
                                                    </div>
                                                    <input
                                                        className={`${styles.input_field} ${formData.mobile.length > 0 ? styles.input_field2 : ''} ${isFocused ? styles.input_field2 : ''}`}
                                                        type="tel"
                                                        maxLength={10}
                                                        value={formData.mobile}
                                                        onChange={(e) => handleInputChange(e, 'mobile')}
                                                        required
                                                        onFocus={handleInputFocus}
                                                        onBlur={handleInputBlur}
                                                    />
                                                    <div className={`${styles.float_label} ${formData.mobile && styles.labelActive}`}>
                                                        {isFocused ? "Enter your mobile number" : formData.mobile.length === 0 ? "+91 Enter your mobile number" : "Enter your mobile number"} <span style={{ color: `var(--red)` }}>*</span>
                                                    </div>
                                                    {localStorage.setItem('buyer_phone', formData.mobile)}
                                                </div>
                                                {emptyFields.includes("mobile") && (formData.mobile === 0 || formData.mobile === "") && (
                                                    <p className={styles.error}> Mobile is required</p>
                                                )}
                                                {formData.mobile && !/^[6789]\d{9}$/.test(formData.mobile) && (
                                                    <p className={styles.error}>
                                                        Please provide an Indian number
                                                    </p>
                                                )}

                                                <div className={styles.MUI_input}>
                                                    <div className={styles.option_container}>
                                                        <FormControl
                                                            variant="outlined"
                                                            fullWidth
                                                        >
                                                            <InputLabel
                                                                className={classes.label}
                                                                id="demo-simple-select-outlined-label"
                                                            >
                                                                Select Your State <span style={{ color: `var(--red)` }}>*</span>
                                                            </InputLabel>
                                                            <Select
                                                                required
                                                                labelId="demo-simple-select-outlined-label"
                                                                id="demo-simple-select-outlined"
                                                                value={formData.state}
                                                                onChange={(e) => handleInputChange(e, 'state')}
                                                                label="Select Your State"
                                                                renderValue={(selected) => <span className={classes.selectedOption}>{selected}</span>}
                                                               
                                                                className={`${classes.select} ${formData.state.length > 0 ? classes.filled : ''}`}
                                                                MenuProps={{
                                                                    anchorOrigin: {
                                                                        vertical: 'bottom',
                                                                        horizontal: 'left',
                                                                    },
                                                                    transformOrigin: {
                                                                        vertical: 'top',
                                                                        horizontal: 'left',
                                                                    },
                                                                    getContentAnchorEl: null,
                                                                    autowidth: "false",
                                                                }}
                                                                menulistprops={{
                                                                    autoFocus: true,
                                                                }}
                                                                onFocus={() => setIsFocused(true)}
                                                                onBlur={() => setIsFocused(false)}
                                                            >
                                                                {states.map((state) => (
                                                                    <MenuItem
                                                                        key={state.stateID}
                                                                        value={state.state}
                                                                        className={`${classes.option}`}
                                                                    >
                                                                        {state.state}
                                                                    </MenuItem>
                                                                ))}

                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </div>
                                                {emptyFields.includes("state") && formData.state === "" && (
                                                    <p className={styles.error}>State is required</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className={styles.final_button_container1}>
                                            <button
                                                className={styles.submit_button1}
                                                type='submit'
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </>
                                )}
                            </form>
                        </div>
                    )
                }
            </div>
        </>
    )
}

export default PartnerForm