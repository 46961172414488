import React from 'react'
import ConsumerMain from '../../../../Layout/Consumer/Main/ConsumerMain'
import { useState, useEffect } from 'react';
import styles from './LoanApplication.module.css';
import upload_image from '../../../../images/forms/upload_icon.svg'
import thank_you from '../../../../images/forms/consumer/consumer_thank_you.svg'
import axios from 'axios';
import { GoDownload } from "react-icons/go";
import { Box, Modal } from '@mui/material';
import { jwtDecode } from 'jwt-decode';
import { IoEyeOffOutline, IoEyeOutline, IoEyeSharp } from "react-icons/io5";
import { IoMdClose, IoMdEyeOff } from "react-icons/io";
import { GoUpload } from 'react-icons/go';
import { MdDeleteOutline, MdKeyboardArrowDown, MdOutlineDeleteOutline } from "react-icons/md";
import { IoCloseCircleSharp, IoCloseSharp } from "react-icons/io5";
import {
    MenuItem,
    InputLabel,
    FormControl,
    makeStyles,
    Select,
} from '@material-ui/core';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import useStyles from '../../../../reusable/DropdownStyle/useStyles';
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
).toString();

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "none",
    border: "none",
};

const LoanApplication = () => {
    const classes = useStyles();
    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    // MUI MODAL
    const [stage, setStage] = useState(1);
    const [openMUIModal, setOpenMUIModal] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const handleOpen = () => setOpenMUIModal(true);
    const handleClose = () => setOpenMUIModal(false);

    const [emptyFields, setEmptyFields] = useState([]);
    const [copyAddress, setCopyAddress] = useState(false);

    const token = localStorage.getItem('Authorization');
    const my_dealer_id = localStorage.getItem('my-dealer-id');

    const credit_score = localStorage.getItem('credit_score');
    const credit_file = localStorage.getItem('credit_file');
    const info = jwtDecode(token);
    // console.log('my-dealer-id', my_dealer_id);
    const [isFocused, setIsFocused] = useState(false);
    const handleInputFocus = () => {
        setIsFocused(true);
    };

    const handleInputBlur = () => {
        setIsFocused(false);
    };

    const [fileNames, setFileNames] = useState({
        pan: '',
        upload_address: '',
        upload_address_back: '',
        itr: '',
        bank_statement: '',
        salary_slip: '',
        gst: '',
    });

    const stored_id = localStorage.getItem('my_consumer_stored_id')

    const [formData, setFormData] = useState({
        id: stored_id,
        consumer_id: info.id,
        dealer_id: my_dealer_id,
        full_name: '',
        email: '',
        employee_type: '',
        loan_amount: '',
        existing_emi: '',
        house: '',
        vehicle_number: '',
        permanent_address: '',
        correspondence_address: '',
        consent: "1",
        address_proof: '',
        salary: '',

        mother_name: '',
        mobile: info.username,
        salary: '',
        city: '',
        pincode: '',
        district: '',
        city_c: '',
        pincode_c: '',
        district_c: '',
        credit_score: credit_score,
        credit_file: credit_file,
    });


    const [imagePreviews, setImagePreviews] = useState({
        upload_address: null,
        upload_address_back: null,
        itr: null,
        pan: null,
        salary_slip: null,
        bank_statement: null,
    });

    const handleInputChange = (e, inputName) => {
        const newValue = e.target.files ? e.target.files[0] : e.target.value;

        setFormData((prevFormData) => ({
            ...prevFormData,
            [inputName]: newValue,
        }));
    };

    const handleFileUpload = async (e, fieldName) => {
        try {

            const file = e.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = (readerEvent) => {
                    setImagePreviews((prevPreviews) => ({
                        ...prevPreviews,
                        [fieldName]: readerEvent.target.result,
                    }));
                };
            }

            const files = e.target.files[0]
            const formData = new FormData();
            formData.append('files', files);

            const response = await axios.post('/imageUploads', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `${token}`,
                },

            })
            setFileNames((prevFileNames) => ({
                ...prevFileNames,
                [fieldName]: response.data.file_name,
            }));

        } catch (error) {
            console.error('Error uploading file:', error.message);
        }
    };

    const handleCheckboxChange = () => {
        setCopyAddress(!copyAddress);

        if (!copyAddress) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                correspondence_address: prevFormData.permanent_address,
                pincode_c: prevFormData.pincode,
                city_c: prevFormData.city,
                district_c: prevFormData.district
            }));
        }
    };

    const validateStage1 = () => {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return (
            emailPattern.test(formData.email) &&
            formData.full_name !== '' &&
            formData.mother_name !== '' &&
            formData.employee_type !== '' &&
            formData.house !== ''
        );
    };

    const validateStage2 = () => {
        return (
            formData.salary !== '' &&
            formData.existing_emi !== '' &&
            formData.loan_amount !== '' &&
            // formData.vehicle_number !== '' &&
            formData.permanent_address !== '' &&
            formData.pincode !== '' &&
            formData.district !== '' &&
            formData.city !== '' &&
            formData.pincode_c !== '' &&
            formData.district_c !== '' &&
            formData.city_c !== '' &&
            formData.correspondence_address !== ''
        );
    };

    const validateStage3 = () => {
        return (
            formData.address_proof !== '' &&
            fileNames.upload_address !== '' &&
            fileNames.upload_address_back !== '' &&
            fileNames.itr !== '' &&
            fileNames.pan !== '' &&
            fileNames.salary_slip !== '' &&
            fileNames.bank_statement !== ''
        );
    };

    const handleNextStage = async (e) => {
        e.preventDefault()
        const emptyFields = [];
        if (stage === 1 && !validateStage1()) {
            emptyFields.push("email", "full_name", "mother_name", "employee_type", "house");
        }

        if (stage === 2 && !validateStage2()) {
            emptyFields.push("salary", "existing_emi", "pincode", "district", "city", "pincode_c", "district_c", "city_c", "loan_amount", "permanent_address", "correspondence_address");
        }

        if (stage === 3 && !validateStage3()) {
            emptyFields.push("address_proof", "upload_address", "itr", "pan", "salary_slip", "bank_statement");
        }

        if (stage === 1) {
            try {
                const response = await axios.post('/applicationFormOne', formData, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `${token}`,
                    },
                });
                localStorage.setItem('my_consumer_stored_id', response.data.id)
            } catch (error) {
                console.error('Error calling Stage 1 API:', error);
            }
        }
        if (stage === 2) {
            try {
                const response = await axios.post('/applicationFormTwo', formData, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `${token}`,
                    },
                });
            } catch (error) {
                console.error('Error calling Stage 1 API:', error);
            }
        }
        if (stage === 3) {
            try {
                const imageFiles = {
                    ...fileNames,
                    ...formData,
                }
                const response = await axios.post('/applicationFormThree', imageFiles, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `${token}`,
                    },
                });
            } catch (error) {
                console.error('Error calling Stage 1 API:', error);
            }
        }

        setEmptyFields(emptyFields);

        if (emptyFields.length === 0) {
            setStage(stage + 1);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formDataWithFileNames = {
                "final_status": "1",
                "id": stored_id,
            };
            const response = await axios.post('/applicationFormFour', formDataWithFileNames, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${token}`,
                },
            })
            setIsFormSubmitted(true);
            handleOpen();

        } catch (error) {
            console.error('Error submitting form:', error.message);
        }
    };

    const handlePrevStage = () => {
        setStage(stage - 1);
    };

    const [showUploadAddressPreview, setShowUploadAddressPreview] = useState(false);
    const [showUploadAddressPreview1, setShowUploadAddressPreview1] = useState(false);
    const [showItrPreview, setShowItrPreview] = useState(false);
    const [showPanPreview, setShowPanPreview] = useState(false);
    const [showSalarySlipPreview, setShowSalarySlipPreview] = useState(false);
    const [showBankStatementPreview, setShowBankStatementPreview] = useState(false);

    const indianPincodeRegex = /^[1-9][0-9]{5}$/;
    const isValidPincode = (pincode) => {
        return indianPincodeRegex.test(pincode);
    };
    
    const handleFetchDetails = async (e) => {
        try {
            const response = await axios.get(`/pincode?pincode=${formData.pincode}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (response && response.data.district) {
                setFormData((prevData) => ({
                    ...prevData,
                    district: response.data.district,
                    city: response.data.state,
                }));
            } else {
                setFormData((prevData) => ({
                    ...prevData,
                    city: 'No Data',
                    district: 'No Data',
                }));
            }
        } catch (error) {
            console.log('error', error.message)
        }
    }

    const handleFetchDetails1 = async (e) => {
        try {
            const response = await axios.get(`/pincode?pincode=${formData.pincode}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (response && response.data.district) {
                setFormData((prevData) => ({
                    ...prevData,
                    district: response.data.district,
                    city: response.data.state,
                }));
            } else {
                setFormData((prevData) => ({
                    ...prevData,
                    city: 'No Data',
                    district: 'No Data',
                }));
            }
        } catch (error) {
            console.log('error', error.message)
        }
    }

    const handleDelete = (fileType) => {
        setFileNames((prevFileNames) => ({
            ...prevFileNames,
            [fileType]: '',
        }));
        setImagePreviews((prevImagePreviews) => ({
            ...prevImagePreviews,
            [fileType]: null,
        }));
    };


    return (
        <>
            <div className={styles.container}>
                {
                    isFormSubmitted ?
                        (
                            <div className={styles.successContainer}>
                                <img src={thank_you} alt="Success" />
                            </div>
                        ) : (
                            <div className={styles.right}>
                                <form onSubmit={handleSubmit}>

                                    {
                                        stage === 1 && (
                                            <>
                                                {/* WELCOME TEXT */}
                                                <div className={styles.welcome_text}>
                                                    <div className={styles.welcome_text1}>
                                                        Loan Application Form
                                                    </div>
                                                    <div className={styles.welcome_text2}>
                                                        Please fill out all the details
                                                    </div>
                                                </div>
                                                <div className={styles.form}>
                                                    <div className={styles.col}>
                                                        <div className={styles.MUI_input}>
                                                            <input
                                                                className={formData.full_name.length > 0 ? styles.input_field1 : styles.input_field}
                                                                type="text"
                                                                value={formData.full_name}
                                                                onChange={(e) => handleInputChange(e, 'full_name')}
                                                                required
                                                            />
                                                            <div className={`${styles.float_label} ${formData.full_name && styles.labelActive}`}>
                                                                Enter Full Name
                                                            </div>
                                                        </div>
                                                        {emptyFields.includes("full_name") && formData.full_name === "" && (
                                                            <p className={styles.error}>Full Name is required</p>
                                                        )}


                                                        <div className={styles.MUI_input}>
                                                            <input
                                                                className={formData.mother_name.length > 0 ? styles.input_field1 : styles.input_field}
                                                                type="text"
                                                                value={formData.mother_name}
                                                                onChange={(e) => handleInputChange(e, 'mother_name')}
                                                                required
                                                            />
                                                            <div className={`${styles.float_label} ${formData.mother_name && styles.labelActive}`}>
                                                                Enter Mother's Name
                                                            </div>
                                                        </div>
                                                        {emptyFields.includes("mother_name") && formData.mother_name === "" && (
                                                            <p className={styles.error}>Mother's Name is required</p>
                                                        )}

                                                        <div className={styles.MUI_input}>
                                                            <input
                                                                className={formData.email.length > 0 ? styles.input_field1 : styles.input_field}
                                                                type="email"
                                                                pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                                                                value={formData.email}
                                                                onChange={(e) => handleInputChange(e, 'email')}
                                                                required
                                                            />
                                                            <div className={`${styles.float_label} ${formData.email && styles.labelActive}`}>
                                                                Enter Email ID
                                                            </div>
                                                        </div>
                                                        {emptyFields.includes("email") && formData.email === "" && (
                                                            <p className={styles.error}>Email is required</p>
                                                        )}
                                                        {!emptyFields.includes("email") && formData.email !== "" && !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email) && (
                                                            <p className={styles.error}>Invalid email format</p>
                                                        )}

                                                        {/* DROPDOWN */}
                                                        <div className={styles.MUI_input}>
                                                            <div className={styles.option_container}>
                                                                <FormControl
                                                                    variant="outlined"
                                                                    fullWidth
                                                                >
                                                                    <InputLabel
                                                                        className={classes.label}
                                                                        id="demo-simple-select-outlined-label"
                                                                    >
                                                                        Employment Type
                                                                    </InputLabel>
                                                                    <Select
                                                                        sx={{ "& .MuiSvgIcon-root": { color: "red" } }}
                                                                        labelId="demo-simple-select-outlined-label"
                                                                        id="demo-simple-select-outlined"
                                                                        value={formData.employee_type}
                                                                        onChange={(e) => handleInputChange(e, 'employee_type')}
                                                                        label="Employment Type"
                                                                        renderValue={(selected) => <span className={classes.selectedOption}>{selected}</span>}
                    
                                                                        className={`${classes.select} ${formData.employee_type.length > 0 ? classes.filled : ''
                                                                            }`}
                                                                        MenuProps={{
                                                                            anchorOrigin: {
                                                                                vertical: 'bottom',
                                                                                horizontal: 'left',
                                                                            },
                                                                            transformOrigin: {
                                                                                vertical: 'top',
                                                                                horizontal: 'left',
                                                                            },
                                                                            getContentAnchorEl: null,
                                                                            autoWidth: false,
                                                                        }}
                                                                        MenuListProps={{
                                                                            autoFocus: true,
                                                                        }}
                                                                        onFocus={() => setIsFocused(true)} // Update the focus state when the Select receives focus
                                                                        onBlur={() => setIsFocused(false)}
                                                                    >
                                                                        <MenuItem
                                                                            value="Salaried"
                                                                            className={`${classes.option}`}>
                                                                            Salaried
                                                                        </MenuItem>
                                                                        <MenuItem value="Self Employed" className={`${classes.option}`}>
                                                                            Self Employed
                                                                        </MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                        {emptyFields.includes("employee_type") && formData.employee_type === "" && (
                                                            <p className={styles.error}>Employment Type is required</p>
                                                        )}

                                                        {/* DROPDOWN  */}
                                                        <div className={styles.MUI_input}>
                                                            <div className={styles.option_container}>
                                                                <FormControl variant="outlined" fullWidth>
                                                                    <InputLabel
                                                                        className={classes.label}
                                                                        id="demo-simple-select-outlined-label"
                                                                    >
                                                                        House
                                                                    </InputLabel>
                                                                    <Select
                                                                        sx={{
                                                                            outline: 'none',
                                                                            boxShadow: 'none',
                                                                            '.MuiOutlinedInput-notchedOutline': { border: 0 }
                                                                        }}
                                                                        labelId="demo-simple-select-outlined-label"
                                                                        id="demo-simple-select-outlined"
                                                                        value={formData.house}
                                                                        onChange={(e) => handleInputChange(e, 'house')}
                                                                        label="House"
                                                                        renderValue={(selected) => <span className={classes.selectedOption}>{selected}</span>}
                                                                    
                                                                        className={`${classes.select} ${formData.house.length > 0 ? classes.filled : ''
                                                                            }`}
                                                                        MenuProps={{
                                                                            anchorOrigin: {
                                                                                vertical: 'bottom',
                                                                                horizontal: 'left',
                                                                            },
                                                                            transformOrigin: {
                                                                                vertical: 'top',
                                                                                horizontal: 'left',
                                                                            },
                                                                            getContentAnchorEl: null,
                                                                            autoWidth: true,
                                                                        }}
                                                                    >
                                                                        <MenuItem
                                                                            value="Rented"
                                                                            className={`${classes.option}`}>
                                                                            Rented
                                                                        </MenuItem>
                                                                        <MenuItem value="Owned" className={`${classes.option}`}>
                                                                            Owned
                                                                        </MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                        </div>

                                                        {emptyFields.includes("house") && formData.house === "" && (
                                                            <p className={styles.error}>House is required</p>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className={styles.final_button_container1}>
                                                    <button
                                                        className={styles.submit_button1}
                                                        onClick={handleNextStage}
                                                    >
                                                        Next
                                                    </button>
                                                </div>
                                            </>
                                        )
                                    }

                                    {
                                        stage === 2 && (
                                            <>
                                                {/* WELCOME TEXT */}
                                                <div className={styles.welcome_text}>
                                                    <div className={styles.welcome_text1}>
                                                        Loan Application Form
                                                    </div>
                                                    <div className={styles.welcome_text2}>
                                                        Please fill out all the details
                                                    </div>
                                                </div>
                                                <div className={styles.form}>
                                                    <div className={styles.col}>

                                                        <div className={styles.MUI_input}>
                                                            <input
                                                                className={formData.salary.length > 0 ? styles.input_field1 : styles.input_field}
                                                                type="number"
                                                                value={formData.salary}
                                                                onChange={(e) => handleInputChange(e, 'salary')}
                                                                required
                                                            />
                                                            <div className={`${styles.float_label} ${formData.salary && styles.labelActive}`}
                                                            >
                                                                Enter Monthly Income <span style={{ color: `var(--red)` }}>*</span>
                                                            </div>
                                                        </div>
                                                        {emptyFields.includes("salary") && formData.salary === "" && (
                                                            <p className={styles.error}> Enter Monthly Income </p>
                                                        )}

                                                        <div className={styles.MUI_input}>
                                                            <input
                                                                className={formData.existing_emi.length > 0 ? styles.input_field1 : styles.input_field}
                                                                type="number"
                                                                value={formData.existing_emi}
                                                                onChange={(e) => handleInputChange(e, 'existing_emi')}
                                                                required
                                                            />
                                                            <div className={`${styles.float_label} ${formData.existing_emi && styles.labelActive}`}
                                                            >
                                                                Existing EMI <span style={{ color: `var(--red)` }}>*</span>
                                                            </div>
                                                        </div>
                                                        {emptyFields.includes("existing_emi") && formData.existing_emi === "" && (
                                                            <p className={styles.error}>Existing EMI is required</p>
                                                        )}


                                                        <div className={styles.MUI_input}>
                                                            <input
                                                                className={formData.loan_amount.length > 0 ? styles.input_field1 : styles.input_field}
                                                                type="number"
                                                                value={formData.loan_amount}
                                                                onChange={(e) => handleInputChange(e, 'loan_amount')}
                                                                required
                                                            />
                                                            <div className={`${styles.float_label} ${formData.loan_amount && styles.labelActive}`}
                                                            >
                                                                Enter Loan Amount to be Financed <span style={{ color: `var(--red)` }}>*</span>

                                                            </div>
                                                        </div>
                                                        {emptyFields.includes("loan_amount") && formData.loan_amount === "" && (
                                                            <p className={styles.error}>Loan Amount is required</p>
                                                        )}



                                                        <div className={styles.MUI_input}>
                                                            <input
                                                                // required
                                                                className={formData.vehicle_number.length > 0 ? styles.input_field1 : styles.input_field}
                                                                type="text"
                                                                value={formData.vehicle_number}
                                                                onChange={(e) => handleInputChange(e, 'vehicle_number')}
                                                                pattern='^[A-Z]{2}[0-9]{1,2}(?:[A-Z])?(?:[A-Z]*)?[0-9]{4}$'
                                                                maxLength={10}
                                                                title='FORMAT DL AE 01 2321'
                                                                onInput={(e) => {
                                                                    e.target.value = e.target.value.toUpperCase();
                                                                }}
                                                            />
                                                            <div className={`${styles.float_label_2} ${formData.vehicle_number && styles.labelActive}`}
                                                            >
                                                                Vehicle Number of Vehicle to be Purchased
                                                                <span className={styles.vehicle_number_avilable}>
                                                                    (If Available)
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {emptyFields.includes('vehicle_number') && formData.vehicle_number === '' && (
                                                            <p className={styles.error}>Vehicle Number is required</p>
                                                        )}

                                                        {formData.vehicle_number && !/^[A-Z]{2}[0-9]{1,2}(?:[A-Z])?(?:[A-Z]*)?[0-9]{4}$/.test(formData.vehicle_number) && (
                                                            <p className={styles.error}>
                                                                Please provide a valid Indian vehicle number
                                                            </p>
                                                        )}




                                                        {/* ADDRESS Fields */}
                                                        <div className={styles.address_fields}>

                                                            <div className={styles.MUI_input}>
                                                                <input
                                                                    className={formData.permanent_address.length > 0 ? styles.input_field1 : styles.input_field}
                                                                    type="text"
                                                                    required
                                                                    value={formData.permanent_address}
                                                                    onChange={(e) => handleInputChange(e, 'permanent_address')}
                                                                />
                                                                <div className={`${styles.float_label} ${formData.permanent_address && styles.labelActive}`}>
                                                                    Permanent Address
                                                                </div>
                                                            </div>
                                                            {emptyFields.includes("permanent_address") && formData.permanent_address === "" && (
                                                                <p className={styles.error}>Permanent Address is required</p>
                                                            )}

                                                            <div className={styles.MUI_input}>
                                                                <input
                                                                    className={formData.pincode.length > 0 ? styles.input_field1 : styles.input_field}
                                                                    type="tel"
                                                                    maxLength={6}
                                                                    minLength={6}
                                                                    value={formData.pincode}
                                                                    onChange={(e) => handleInputChange(e, 'pincode')}
                                                                    onBlur={() => handleFetchDetails(formData.pincode)}
                                                                    required
                                                                />
                                                                <div className={`${styles.float_label} ${formData.pincode && styles.labelActive}`}
                                                                >
                                                                    Enter PIN Code
                                                                </div>
                                                            </div>
                                                            {emptyFields.includes("pincode") && formData.pincode === "" && (
                                                                <p className={styles.error}>Pincode is required</p>
                                                            )}
                                                            {formData.pincode !== '' && !isValidPincode(formData.pincode) && (
                                                                <p className={styles.error}>Invalid PIN code</p>
                                                            )}


                                                            <div className={styles.single_address}>
                                                                <div className={styles.single_address_error} style={{ width: '100%' }}>
                                                                    <div className={styles.MUI_input} style={{ width: '100%' }}>
                                                                        <input
                                                                            className={formData.city.length > 0 ? styles.input_field1 : styles.input_field}
                                                                            style={{ width: '95%' }}
                                                                            type="text"
                                                                            value={formData.city}
                                                                            onChange={(e) => handleInputChange(e, 'city')}
                                                                            required
                                                                        />
                                                                        <div className={`${styles.float_label} ${formData.city && styles.labelActive}`}
                                                                        >
                                                                            State
                                                                        </div>
                                                                    </div>
                                                                    {emptyFields.includes("city") && formData.city === "" && (
                                                                        <p className={styles.address_error}>State is required</p>
                                                                    )}
                                                                </div>

                                                                <div className={styles.single_address_error} style={{ width: '100%' }}>
                                                                    <div className={styles.MUI_input} style={{ width: '100%' }}>
                                                                        <input
                                                                            className={formData.district.length > 0 ? styles.input_field1 : styles.input_field}
                                                                            style={{ width: '100%' }}
                                                                            type="text"
                                                                            value={formData.district}
                                                                            onChange={(e) => handleInputChange(e, 'district')}
                                                                            required
                                                                        />
                                                                        <div className={`${styles.float_label} ${formData.district && styles.labelActive}`}
                                                                        >
                                                                            District
                                                                        </div>
                                                                    </div>
                                                                    {emptyFields.includes("district") && formData.district === "" && (
                                                                        <p className={styles.address_error} >District is required</p>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            <div className={styles.check_box_container}>
                                                                <div className={styles.first_check_box}>
                                                                    <input
                                                                        className={styles.check_box_single}
                                                                        type="checkbox"
                                                                        onChange={handleCheckboxChange}
                                                                        checked={copyAddress}
                                                                    />
                                                                    <div className={styles.label}>
                                                                        Correspondence address is as same as permanent address
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className={styles.MUI_input}>
                                                                <input
                                                                    className={formData.correspondence_address.length > 0 ? styles.input_field1 : styles.input_field}
                                                                    type="text"
                                                                    required
                                                                    // placeholder='Correspondence Address'
                                                                    value={copyAddress ? formData.permanent_address : formData.correspondence_address}
                                                                    onChange={(e) => handleInputChange(e, 'correspondence_address')}
                                                                    disabled={copyAddress}
                                                                />
                                                                <div className={`${styles.float_label} ${formData.correspondence_address && styles.labelActive}`}>
                                                                    Correspondence Address
                                                                </div>
                                                            </div>
                                                            {emptyFields.includes("correspondence_address") && formData.correspondence_address === "" && (
                                                                <p className={styles.error}>Correspondence Address is required</p>
                                                            )}

                                                            {/* NEW FIELDS */}
                                                            <div className={styles.MUI_input}>
                                                                <input
                                                                    className={formData.pincode_c.length > 0 ? styles.input_field1 : styles.input_field}
                                                                    type="tel"
                                                                    maxLength={6}
                                                                    minLength={6}
                                                                    value={formData.pincode_c}
                                                                    onChange={(e) => handleInputChange(e, 'pincode_c')}
                                                                    onBlur={() => handleFetchDetails1(formData.pincode_c)}
                                                                    required
                                                                />
                                                                <div className={`${styles.float_label} ${formData.pincode_c && styles.labelActive}`}
                                                                >
                                                                    Enter PIN Code
                                                                </div>
                                                            </div>
                                                            {emptyFields.includes("pincode_c") && formData.pincode_c === "" && (
                                                                <p className={styles.error}>Pincode is required</p>
                                                            )}
                                                            {formData.pincode_c !== '' && !isValidPincode(formData.pincode_c) && (
                                                                <p className={styles.error}>Invalid PIN code</p>
                                                            )}
                                                            <div className={styles.single_address}>
                                                                <div className={styles.single_address_error} style={{ width: '100%' }}>
                                                                    <div className={styles.MUI_input} style={{ width: '100%' }}>
                                                                        <input
                                                                            className={formData.city_c.length > 0 ? styles.input_field1 : styles.input_field}
                                                                            style={{ width: '95%' }}
                                                                            type="text"
                                                                            value={formData.city_c}
                                                                            onChange={(e) => handleInputChange(e, 'city_c')}
                                                                            required
                                                                        />
                                                                        <div className={`${styles.float_label} ${formData.city_c && styles.labelActive}`}
                                                                        >
                                                                            State
                                                                        </div>
                                                                    </div>
                                                                    {emptyFields.includes("city_c") && formData.city_c === "" && (
                                                                        <p className={styles.address_error}>State is required</p>
                                                                    )}
                                                                </div>

                                                                <div className={styles.single_address_error} style={{ width: '100%' }}>
                                                                    <div className={styles.MUI_input} style={{ width: '100%' }}>
                                                                        <input
                                                                            className={formData.district_c.length > 0 ? styles.input_field1 : styles.input_field}
                                                                            style={{ width: '100%' }}
                                                                            type="text"
                                                                            value={formData.district_c}
                                                                            onChange={(e) => handleInputChange(e, 'district_c')}
                                                                            required
                                                                        />
                                                                        <div className={`${styles.float_label} ${formData.district_c && styles.labelActive}`}
                                                                        >
                                                                            District
                                                                        </div>
                                                                    </div>
                                                                    {emptyFields.includes("district_c") && formData.district_c === "" && (
                                                                        <p className={styles.address_error} >District is required</p>
                                                                    )}
                                                                </div>
                                                            </div>

                                                        </div>
                                                        {/* <p style={{ paddingBottom: '30px' }}>
                                                            Please be ready to upload KYC details, salary slip, 1 year of ITR & 1 year of bank statement on next screen
                                                        </p> */}
                                                    </div>
                                                </div>

                                                <div className={styles.final_button_container}>
                                                    <button
                                                        className={styles.back_button}
                                                        type="button"
                                                        onClick={handlePrevStage}
                                                    >
                                                        Back
                                                    </button>
                                                    <button
                                                        className={styles.submit_button}
                                                        onClick={handleNextStage}
                                                    >
                                                        Next
                                                    </button>
                                                </div>
                                            </>
                                        )
                                    }

                                    {
                                        stage === 3 && (
                                            <>
                                                {/* WELCOME TEXT */}
                                                <div className={styles.welcome_text}>
                                                    <div className={styles.welcome_text1}>
                                                        Loan Application Form
                                                    </div>
                                                    <div className={styles.welcome_text2}
                                                        style={{ marginBottom: "40px" }}
                                                    >
                                                        Please upload KYC details, salary slip, 1 year of ITR & 1 year of bank statement.
                                                    </div>
                                                </div>
                                                <div className={styles.col}>
                                                    {/* DRODDOWN */}
                                                    <div className={styles.MUI_input}>
                                                        <div className={styles.option_container}>
                                                            <FormControl variant="outlined" fullWidth>
                                                                <InputLabel
                                                                    className={classes.label}
                                                                    id="demo-simple-select-outlined-label"
                                                                >
                                                                    Address Proof
                                                                </InputLabel>
                                                                <Select

                                                                    sx={{
                                                                        outline: 'none',
                                                                        boxShadow: 'none',
                                                                    }}
                                                                    labelId="demo-simple-select-outlined-label"
                                                                    id="demo-simple-select-outlined"
                                                                    value={formData.address_proof}
                                                                    onChange={(e) => handleInputChange(e, 'address_proof')}
                                                                    label="Adress Proof"
                                                                    renderValue={(selected) => <span className={classes.selectedOption}>{selected}</span>}
                                                                    
                                                                    className={`${classes.select} ${formData.address_proof.length > 0 ? classes.filled : ''}`}
                                                                    MenuProps={{
                                                                        anchorOrigin: {
                                                                            vertical: 'bottom',
                                                                            horizontal: 'left',
                                                                        },
                                                                        transformOrigin: {
                                                                            vertical: 'top',
                                                                            horizontal: 'left',
                                                                        },
                                                                        getContentAnchorEl: null,
                                                                        autoWidth: true,
                                                                    }}
                                                                >
                                                                    <MenuItem
                                                                        value="Aadhaar Card"
                                                                        className={`${classes.option}`}
                                                                    >
                                                                        Aadhaar Card
                                                                    </MenuItem>
                                                                    <MenuItem
                                                                        value="Voting Card"
                                                                        className={`${classes.option}`}
                                                                    >
                                                                        Voting Card
                                                                    </MenuItem>
                                                                    <MenuItem
                                                                        value="Utility Bills"
                                                                        className={`${classes.option}`}
                                                                    >
                                                                        Utility Bills
                                                                    </MenuItem>
                                                                    <MenuItem
                                                                        value="Telephone Bills"
                                                                        className={`${classes.option}`}
                                                                    >
                                                                        Telephone Bills
                                                                    </MenuItem>
                                                                    <MenuItem
                                                                        value="Bank Account Statement"
                                                                        className={`${classes.option}`}
                                                                    >
                                                                        Bank Account Statement
                                                                    </MenuItem>
                                                                    <MenuItem
                                                                        value="Rent Agreement"
                                                                        className={`${classes.option}`}
                                                                    >
                                                                        Rent Agreement
                                                                    </MenuItem>

                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                    </div>

                                                    {emptyFields.includes("address_proof") && formData.address_proof === "" && (
                                                        <p className={styles.error}>Address Proof is required</p>
                                                    )}


                                                    {/* PROOF FILE INPUT FRONT */}
                                                    <div
                                                        className={fileNames.upload_address.length > 0 ? styles.my_upload_label : styles.none}
                                                    >
                                                        Upload Address Proof Front
                                                    </div>
                                                    <input
                                                        type="file"
                                                        id='customFileInput5'
                                                        accept=".pdf, .jpg, .jpeg, .doc, .docx"
                                                        required
                                                        hidden
                                                        onChange={(e) => handleFileUpload(e, 'upload_address')}
                                                    />
                                                    <div className={styles.file_flex}>
                                                        <label
                                                            htmlFor="customFileInput5"
                                                            className={fileNames.upload_address.length > 0 ? styles.customFileInput1_upload : styles.customFileInput1}
                                                        >
                                                            <div className={styles.text}>
                                                                {
                                                                    fileNames.upload_address.length > 0 ?
                                                                        (
                                                                            fileNames.upload_address + ' ' + ''
                                                                        ) :
                                                                        (
                                                                            'Upload Address Proof Front'
                                                                        )
                                                                }
                                                            </div>
                                                            <div className={styles.image}>
                                                                {
                                                                    fileNames.upload_address.length == 0 ? <GoUpload /> : ""
                                                                }
                                                            </div>
                                                        </label>
                                                        <div className={styles.image}>
                                                            {
                                                                fileNames.upload_address !== "" ?
                                                                    (
                                                                        imagePreviews.upload_address && (
                                                                            <div className={styles.eye_icon_container}>
                                                                                <div
                                                                                    className={styles.icon}
                                                                                    onClick={() => setShowUploadAddressPreview(!showUploadAddressPreview)}>
                                                                                    <IoEyeOutline size={24} />
                                                                                </div>
                                                                                <div
                                                                                    className={styles.icon}
                                                                                    onClick={() => handleDelete('upload_address')}
                                                                                >
                                                                                    <MdOutlineDeleteOutline size={24} />
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    )
                                                                    :
                                                                    (
                                                                        null
                                                                    )
                                                            }
                                                        </div>
                                                    </div>
                                                    <Modal
                                                        open={showUploadAddressPreview}
                                                        onClose={() => setShowUploadAddressPreview(false)}
                                                        aria-labelledby="upload-address-preview-modal"
                                                        aria-describedby="upload-address-preview-description"
                                                    >
                                                        <div className={styles.preview}>
                                                            <button
                                                                className={styles.close_button_for_preview}
                                                                onClick={() => setShowUploadAddressPreview(false)}
                                                            >
                                                                <IoCloseCircleSharp />
                                                            </button>
                                                            {fileNames.upload_address &&
                                                                fileNames.upload_address.toLowerCase().endsWith('.pdf') ? (

                                                                <div
                                                                    className={styles.modalImage}
                                                                >
                                                                    <Worker
                                                                        workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}>
                                                                        <Viewer
                                                                            fileUrl={imagePreviews.upload_address}
                                                                        >
                                                                        </Viewer>
                                                                    </Worker>
                                                                </div>
                                                            ) : (
                                                                <img
                                                                    className={styles.modalImage}
                                                                    src={imagePreviews.upload_address}
                                                                    alt="Upload Preview"
                                                                />
                                                            )}
                                                        </div>
                                                    </Modal>
                                                    {/* {console.log("imagePreviews.upload_address", imagePreviews.upload_address)} */}
                                                    {emptyFields.includes("upload_address") && fileNames.upload_address === "" && (
                                                        <p className={styles.error}>Upload Address is required</p>
                                                    )}


                                                    {/* PROOF FILE INPUT BACK */}
                                                    <div
                                                        className={fileNames.upload_address_back.length > 0 ? styles.my_upload_label : styles.none}
                                                    >
                                                        Upload Address Proof Back
                                                    </div>
                                                    <input
                                                        type="file"
                                                        id='customFileInput6'
                                                        accept=".pdf, .jpg, .jpeg"
                                                        required
                                                        hidden
                                                        onChange={(e) => handleFileUpload(e, 'upload_address_back')}
                                                    />
                                                    <div className={styles.file_flex}>
                                                        <label
                                                            htmlFor="customFileInput6"
                                                            className={fileNames.upload_address_back.length > 0 ? styles.customFileInput1_upload : styles.customFileInput1}
                                                        >
                                                            <div className={styles.text}>
                                                                {
                                                                    fileNames.upload_address_back.length > 0 ?
                                                                        (
                                                                            fileNames.upload_address_back + ' ' + ''
                                                                        ) :
                                                                        (
                                                                            'Upload Address Proof Back'
                                                                        )
                                                                }
                                                            </div>

                                                            <div className={styles.image}>
                                                                {
                                                                    fileNames.upload_address_back.length === 0 ? <GoUpload /> : ""
                                                                }
                                                            </div>
                                                        </label>
                                                        <div className={styles.image}>
                                                            {
                                                                fileNames.upload_address_back !== "" ?
                                                                    (
                                                                        imagePreviews.upload_address_back && (
                                                                            <div className={styles.eye_icon_container}>
                                                                                <div
                                                                                    className={styles.icon}
                                                                                    onClick={() => setShowUploadAddressPreview1(!showUploadAddressPreview1)}>
                                                                                    <IoEyeOutline size={24} />
                                                                                </div>
                                                                                <div
                                                                                    className={styles.icon}
                                                                                    onClick={() => handleDelete('upload_address_back')}
                                                                                >
                                                                                    <MdOutlineDeleteOutline size={24} />
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    )
                                                                    :
                                                                    (
                                                                        null
                                                                    )
                                                            }
                                                        </div>
                                                    </div>
                                                    <Modal
                                                        open={showUploadAddressPreview1}
                                                        onClose={() => setShowUploadAddressPreview1(false)}
                                                        aria-labelledby="upload-address-preview-modal"
                                                        aria-describedby="upload-address-preview-description"
                                                    >
                                                        <div className={styles.preview}>
                                                            <button
                                                                className={styles.close_button_for_preview}
                                                                onClick={() => setShowUploadAddressPreview1(false)}
                                                            >
                                                                <IoCloseCircleSharp />
                                                            </button>
                                                            {fileNames.upload_address_back &&
                                                                fileNames.upload_address_back.toLowerCase().endsWith('.pdf') ? (
                                                                <div
                                                                    className={styles.modalImage}
                                                                >
                                                                    <Worker
                                                                        workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}>
                                                                        <Viewer
                                                                            fileUrl={imagePreviews.upload_address_back}
                                                                        >
                                                                        </Viewer>
                                                                    </Worker>
                                                                </div>
                                                            ) : (
                                                                <img
                                                                    className={styles.modalImage}
                                                                    src={imagePreviews.upload_address_back}
                                                                    alt="Upload Preview"
                                                                />
                                                            )}
                                                        </div>
                                                    </Modal>
                                                    {emptyFields.includes("upload_address_back") && fileNames.upload_address_back === "" && (
                                                        <p className={styles.error}>Upload Address Back is required</p>
                                                    )}


                                                    {/* ITR FILE INPUT */}
                                                    <div
                                                        className={fileNames.itr.length > 0 ? styles.my_upload_label : styles.none}
                                                    >
                                                        ITR
                                                    </div>
                                                    <input
                                                        type="file"
                                                        id='customFileInput4'
                                                        accept=".pdf, .jpg, .jpeg"
                                                        required
                                                        hidden
                                                        onChange={(e) => handleFileUpload(e, 'itr')}
                                                    />
                                                    <div className={styles.file_flex}>
                                                        <label htmlFor="customFileInput4"
                                                            className={fileNames.itr.length > 0 ? styles.customFileInput1_upload : styles.customFileInput1}
                                                        >
                                                            <div className={styles.text}>
                                                                {
                                                                    fileNames.itr.length > 0 ?
                                                                        (
                                                                            fileNames.itr
                                                                        ) :
                                                                        (
                                                                            'ITR of 1 Year'
                                                                        )
                                                                }
                                                            </div>
                                                            <div className={styles.image}>
                                                                {
                                                                    fileNames.itr.length == 0 ? <GoUpload /> : ""
                                                                }
                                                            </div>
                                                        </label>
                                                        <div className={styles.image}>
                                                            {
                                                                fileNames.itr !== "" ?
                                                                    (
                                                                        imagePreviews.itr && (
                                                                            <div className={styles.eye_icon_container}>
                                                                                <div
                                                                                    className={styles.icon}
                                                                                    onClick={() => setShowItrPreview(!showItrPreview)}>
                                                                                    <IoEyeOutline size={24} />
                                                                                </div>
                                                                                <div
                                                                                    className={styles.icon}
                                                                                    onClick={() => handleDelete('itr')}
                                                                                >
                                                                                    <MdOutlineDeleteOutline size={24} />
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    )
                                                                    :
                                                                    (
                                                                        null
                                                                    )
                                                            }
                                                        </div>
                                                    </div>
                                                    <Modal
                                                        open={showItrPreview}
                                                        onClose={() => setShowItrPreview(false)}
                                                        aria-labelledby="upload-address-preview-modal"
                                                        aria-describedby="upload-address-preview-description"
                                                    >
                                                        <div className={styles.preview}>
                                                            <button
                                                                className={styles.close_button_for_preview}
                                                                onClick={() => setShowItrPreview(false)}
                                                            >
                                                                <IoCloseCircleSharp />
                                                            </button>
                                                            {fileNames.itr &&
                                                                fileNames.itr.toLowerCase().endsWith('.pdf') ? (
                                                                <div
                                                                    className={styles.modalImage}
                                                                >
                                                                    <Worker
                                                                        workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}>
                                                                        <Viewer
                                                                            fileUrl={imagePreviews.itr}
                                                                        >
                                                                        </Viewer>
                                                                    </Worker>
                                                                </div>
                                                            ) : (
                                                                <img
                                                                    className={styles.modalImage}
                                                                    src={imagePreviews.itr}
                                                                    alt="Upload Preview"
                                                                />
                                                            )}

                                                        </div>
                                                    </Modal>
                                                    {emptyFields.includes("itr") && fileNames.itr === "" && (
                                                        <p className={styles.error}>ITR is required</p>
                                                    )}



                                                    {/* PAN CARD FILE INPUT */}
                                                    <div
                                                        className={fileNames.pan.length > 0 ? styles.my_upload_label : styles.none}
                                                    >
                                                        PAN
                                                    </div>
                                                    <input
                                                        type="file"
                                                        id='customFileInput1'
                                                        accept=".pdf, .jpg, .jpeg"
                                                        required
                                                        hidden
                                                        onChange={(e) => handleFileUpload(e, 'pan')}
                                                    />
                                                    <div className={styles.file_flex}>
                                                        <label htmlFor="customFileInput1"
                                                            className={fileNames.pan.length > 0 ? styles.customFileInput1_upload : styles.customFileInput1}
                                                        >
                                                            <div className={styles.text}>
                                                                {
                                                                    fileNames.pan.length > 0 ?
                                                                        (
                                                                            fileNames.pan
                                                                        ) :
                                                                        (
                                                                            'PAN'
                                                                        )
                                                                }
                                                            </div>
                                                            <div className={styles.image}>
                                                                {
                                                                    fileNames.pan.length == 0 ? <GoUpload /> : ""
                                                                }
                                                            </div>
                                                        </label>
                                                        <div className={styles.image}>
                                                            {
                                                                fileNames.pan !== "" ?
                                                                    (
                                                                        imagePreviews.pan && (
                                                                            <div className={styles.eye_icon_container}>
                                                                                <div
                                                                                    className={styles.icon}
                                                                                    onClick={() => setShowPanPreview(!showPanPreview)}>
                                                                                    <IoEyeOutline size={24} />
                                                                                </div>
                                                                                <div
                                                                                    className={styles.icon}
                                                                                    onClick={() => handleDelete('pan')}
                                                                                >
                                                                                    <MdOutlineDeleteOutline size={24} />
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    )
                                                                    :
                                                                    (
                                                                        null
                                                                    )
                                                            }
                                                        </div>
                                                    </div>
                                                    <Modal
                                                        open={showPanPreview}
                                                        onClose={() => setShowPanPreview(false)}
                                                        aria-labelledby="upload-address-preview-modal"
                                                        aria-describedby="upload-address-preview-description"
                                                    >
                                                        <div className={styles.preview}>
                                                            <button
                                                                className={styles.close_button_for_preview}
                                                                onClick={() => setShowPanPreview(false)}
                                                            >
                                                                <IoCloseCircleSharp />
                                                            </button>
                                                            {fileNames.pan &&
                                                                fileNames.pan.toLowerCase().endsWith('.pdf') ? (
                                                                <div
                                                                    className={styles.modalImage}
                                                                >
                                                                    <Worker
                                                                        workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}>
                                                                        <Viewer
                                                                            fileUrl={imagePreviews.pan}
                                                                        >
                                                                        </Viewer>
                                                                    </Worker>
                                                                </div>
                                                            ) : (
                                                                <img
                                                                    className={styles.modalImage}
                                                                    src={imagePreviews.pan}
                                                                    alt="Upload Preview"
                                                                />
                                                            )}
                                                        </div>
                                                    </Modal>
                                                    {emptyFields.includes("pan") && fileNames.pan === "" && (
                                                        <p className={styles.error}>PAN is required</p>
                                                    )}




                                                    {/* Salary Slip FILE INPUT OR GST */}
                                                    {
                                                        formData.employee_type === "Salaried" ?
                                                            (
                                                                <>
                                                                    <div
                                                                        className={fileNames.salary_slip.length > 0 ? styles.my_upload_label : styles.none}
                                                                    >
                                                                        {(
                                                                            formData.employee_type === "Salaried" ?
                                                                                'Salary Slip of Last 3 Months' :
                                                                                'GST Certificate'
                                                                        )}
                                                                    </div>
                                                                    <input
                                                                        type="file"
                                                                        id='customFileInput2'
                                                                        accept=".pdf, .jpg, .jpeg"
                                                                        required
                                                                        hidden
                                                                        onChange={(e) => handleFileUpload(e, 'salary_slip')}
                                                                    />
                                                                    <div className={styles.file_flex}>
                                                                        <label htmlFor="customFileInput2"
                                                                            className={fileNames.salary_slip.length > 0 ? styles.customFileInput1_upload : styles.customFileInput1}
                                                                        >
                                                                            <div className={styles.text}>
                                                                                {fileNames.salary_slip.length > 0 ? (
                                                                                    fileNames.salary_slip
                                                                                ) : (
                                                                                    formData.employee_type === "Salaried" ?
                                                                                        'Salary Slip of Last 3 Months' :
                                                                                        'GST Certificate'
                                                                                )}
                                                                            </div>
                                                                            <div className={styles.image}>
                                                                                {
                                                                                    fileNames.salary_slip.length == 0 ? <GoUpload /> : ""
                                                                                }
                                                                            </div>
                                                                        </label>
                                                                        <div className={styles.image}>
                                                                            {
                                                                                fileNames.salary_slip !== "" ?
                                                                                    (
                                                                                        imagePreviews.itr && (
                                                                                            <div className={styles.eye_icon_container}>
                                                                                                <div
                                                                                                    className={styles.icon}
                                                                                                    onClick={() => setShowSalarySlipPreview(!showSalarySlipPreview)}>
                                                                                                    <IoEyeOutline size={24} />
                                                                                                </div>
                                                                                                <div
                                                                                                    className={styles.icon}
                                                                                                    onClick={() => handleDelete('salary_slip')}
                                                                                                >
                                                                                                    <MdOutlineDeleteOutline size={24} />
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    )
                                                                                    :
                                                                                    (
                                                                                        null
                                                                                    )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <Modal
                                                                        open={showSalarySlipPreview}
                                                                        onClose={() => setShowSalarySlipPreview(false)}
                                                                        aria-labelledby="upload-address-preview-modal"
                                                                        aria-describedby="upload-address-preview-description"
                                                                    >
                                                                        <div className={styles.preview}>
                                                                            <button
                                                                                className={styles.close_button_for_preview}
                                                                                onClick={() => setShowSalarySlipPreview(false)}
                                                                            >
                                                                                <IoCloseCircleSharp />
                                                                            </button>
                                                                            {fileNames.salary_slip &&
                                                                                fileNames.salary_slip.toLowerCase().endsWith('.pdf') ? (
                                                                                <div
                                                                                    className={styles.modalImage}
                                                                                >
                                                                                    <Worker
                                                                                        workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}>
                                                                                        <Viewer
                                                                                            fileUrl={imagePreviews.salary_slip}
                                                                                        >
                                                                                        </Viewer>
                                                                                    </Worker>
                                                                                </div>
                                                                            ) : (
                                                                                <img
                                                                                    className={styles.modalImage}
                                                                                    src={imagePreviews.salary_slip}
                                                                                    alt="Upload Preview"
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </Modal>
                                                                    {emptyFields.includes("salary_slip") && fileNames.salary_slip === "" && (
                                                                        <p className={styles.error}>Salary Slip is required</p>
                                                                    )}
                                                                </>
                                                            ) :
                                                            (
                                                                <>
                                                                    <div
                                                                        className={fileNames.salary_slip.length > 0 ? styles.my_upload_label : styles.none}
                                                                    >
                                                                        {(
                                                                            formData.employee_type === "Salaried" ?
                                                                                'Salary Slip of Last 3 Months' :
                                                                                'GST Certificate'
                                                                        )}
                                                                    </div>
                                                                    <input
                                                                        type="file"
                                                                        id='customFileInput2'
                                                                        accept=".pdf, .jpg, .jpeg"
                                                                        required
                                                                        hidden
                                                                        onChange={(e) => handleFileUpload(e, 'gst')}
                                                                    />
                                                                    <div className={styles.file_flex}>
                                                                        <label htmlFor="customFileInput2"
                                                                            className={fileNames.gst.length > 0 ? styles.customFileInput1_upload : styles.customFileInput1}
                                                                        >
                                                                            <div className={styles.text}>
                                                                                {fileNames.gst.length > 0 ? (
                                                                                    fileNames.gst
                                                                                ) : (
                                                                                    formData.employee_type === "Salaried" ?
                                                                                        'Salary Slip of Last 3 Months' :
                                                                                        'GST Certificate'
                                                                                )}
                                                                            </div>
                                                                            <div className={styles.image}>
                                                                                {
                                                                                    fileNames.gst.length == 0 ? <GoUpload /> : ""
                                                                                }
                                                                            </div>
                                                                        </label>
                                                                        <div className={styles.image}>
                                                                            {
                                                                                fileNames.gst !== "" ?
                                                                                    (
                                                                                        imagePreviews.gst && (
                                                                                            <div className={styles.eye_icon_container}>
                                                                                                <div
                                                                                                    className={styles.icon}
                                                                                                    onClick={() => setShowSalarySlipPreview(!showSalarySlipPreview)}>
                                                                                                    <IoEyeOutline size={24} />
                                                                                                </div>
                                                                                                <div
                                                                                                    className={styles.icon}
                                                                                                    onClick={() => handleDelete('gst')}
                                                                                                >
                                                                                                    <MdOutlineDeleteOutline size={24} />
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    )
                                                                                    :
                                                                                    (
                                                                                        null
                                                                                    )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <Modal
                                                                        open={showSalarySlipPreview}
                                                                        onClose={() => setShowSalarySlipPreview(false)}
                                                                        aria-labelledby="upload-address-preview-modal"
                                                                        aria-describedby="upload-address-preview-description"
                                                                    >
                                                                        <div className={styles.preview}>
                                                                            <button
                                                                                className={styles.close_button_for_preview}
                                                                                onClick={() => setShowSalarySlipPreview(false)}
                                                                            >
                                                                                <IoCloseCircleSharp />
                                                                            </button>
                                                                            {fileNames.gst &&
                                                                                fileNames.gst.toLowerCase().endsWith('.pdf') ? (
                                                                                <div
                                                                                    className={styles.modalImage}
                                                                                >
                                                                                    <Worker
                                                                                        workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}>
                                                                                        <Viewer
                                                                                            fileUrl={imagePreviews.gst}
                                                                                        >
                                                                                        </Viewer>
                                                                                    </Worker>
                                                                                </div>
                                                                            ) : (
                                                                                <img
                                                                                    className={styles.modalImage}
                                                                                    src={imagePreviews.gst}
                                                                                    alt="Upload Preview"
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </Modal>
                                                                    {emptyFields.includes("gst") && fileNames.gst === "" && (
                                                                        <p className={styles.error}>GST is required</p>
                                                                    )}
                                                                </>
                                                            )
                                                    }


                                                    {/* BANK STATEMENT FILE INPUT */}
                                                    <div
                                                        className={fileNames.bank_statement.length > 0 ? styles.my_upload_label : styles.none}
                                                    >
                                                        Bank Statement of Last 6 Months
                                                    </div>
                                                    <input
                                                        type="file"
                                                        id='customFileInput3'
                                                        accept=".pdf, .jpg, .jpeg"
                                                        required
                                                        hidden
                                                        onChange={(e) => handleFileUpload(e, 'bank_statement')}
                                                    />
                                                    <div className={styles.file_flex}>
                                                        <label htmlFor="customFileInput3"
                                                            className={fileNames.bank_statement.length > 0 ? styles.customFileInput1_upload : styles.customFileInput1}
                                                        >
                                                            <div className={styles.text}>
                                                                {
                                                                    fileNames.bank_statement.length > 0 ?
                                                                        (
                                                                            fileNames.bank_statement
                                                                        ) :
                                                                        (
                                                                            'Bank Statement of Last 6 Months'
                                                                        )
                                                                }
                                                            </div>
                                                            <div className={styles.image}>
                                                                {
                                                                    fileNames.bank_statement.length == 0 ? <GoUpload /> : ""
                                                                }
                                                            </div>
                                                        </label>
                                                        <div className={styles.image}>
                                                            {
                                                                fileNames.bank_statement !== "" ?
                                                                    (
                                                                        imagePreviews.bank_statement && (
                                                                            <div className={styles.eye_icon_container}>
                                                                                <div
                                                                                    className={styles.icon}
                                                                                    onClick={() => setShowBankStatementPreview(!showBankStatementPreview)}>
                                                                                    <IoEyeOutline size={24} />
                                                                                </div>
                                                                                <div
                                                                                    className={styles.icon}
                                                                                    onClick={() => handleDelete('bank_statement')}
                                                                                >
                                                                                    <MdOutlineDeleteOutline size={24} />
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    )
                                                                    :
                                                                    (
                                                                        null
                                                                    )
                                                            }
                                                        </div>
                                                    </div>
                                                    <Modal
                                                        open={showBankStatementPreview}
                                                        onClose={() => setShowBankStatementPreview(false)}
                                                        aria-labelledby="upload-address-preview-modal"
                                                        aria-describedby="upload-address-preview-description"
                                                    >
                                                        <div className={styles.preview}>
                                                            <button
                                                                className={styles.close_button_for_preview}
                                                                onClick={() => setShowBankStatementPreview(false)}
                                                            >
                                                                <IoCloseCircleSharp />
                                                            </button>
                                                            {fileNames.bank_statement &&
                                                                fileNames.bank_statement.toLowerCase().endsWith('.pdf') ? (
                                                                <div
                                                                    className={styles.modalImage}
                                                                >
                                                                    <Worker
                                                                        workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}>
                                                                        <Viewer
                                                                            fileUrl={imagePreviews.bank_statement}
                                                                        >
                                                                        </Viewer>
                                                                    </Worker>
                                                                </div>
                                                            ) : (
                                                                <img
                                                                    className={styles.modalImage}
                                                                    src={imagePreviews.bank_statement}
                                                                    alt="Upload Preview"
                                                                />
                                                            )}
                                                        </div>
                                                    </Modal>
                                                    {emptyFields.includes("bank_statement") && fileNames.bank_statement === "" && (
                                                        <p className={styles.error}>Bank Statement is required</p>
                                                    )}

                                                    <p style={{ marginBottom: "24px" }}>
                                                        Our team will contact you for verification please answer it
                                                    </p>
                                                </div>


                                                {/* BUTTONS CONTAINER */}
                                                <div className={styles.final_button_container}>
                                                    <button
                                                        className={styles.back_button}
                                                        type="button"
                                                        onClick={handlePrevStage}
                                                    >
                                                        Back
                                                    </button>
                                                    <button
                                                        onClick={handleNextStage}
                                                        className={styles.submit_button_preview}
                                                    >
                                                        Preview
                                                    </button>
                                                </div>
                                            </>
                                        )
                                    }

                                    {
                                        stage === 4 && (
                                            <>
                                                <div className={styles.col}>

                                                    {/* Displaying user inputs for each field */}
                                                    <div className={styles.my_preview_container}>
                                                        <table className={styles.preview_table}>
                                                            <tbody>
                                                                <tr>
                                                                    <td><b>Email:</b></td>
                                                                    <td>{formData.email}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b>Full Name:</b></td>
                                                                    <td>{formData.full_name}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b>Mother Name:</b></td>
                                                                    <td>{formData.mother_name}</td>
                                                                </tr>
                                                                {/* <tr>
                                                                    <td><b>Mobile:</b></td>
                                                                    <td>{formData.mobile}</td>
                                                                </tr> */}
                                                                <tr>
                                                                    <td><b>Employee Type:</b></td>
                                                                    <td>{formData.employee_type}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b>House:</b></td>
                                                                    <td>{formData.house}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b>Existing EMI:</b></td>
                                                                    <td>{formData.existing_emi}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b>Loan Amount:</b></td>
                                                                    <td>{formData.loan_amount}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b>Income:</b></td>
                                                                    <td>{formData.salary}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b>Vehicle Number:</b></td>
                                                                    <td>{formData.vehicle_number}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b>Permanent Address:</b></td>
                                                                    <td>{formData.permanent_address}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b>Pincode</b></td>
                                                                    <td>{formData.pincode}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b>City</b></td>
                                                                    <td>{formData.city}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b>District</b></td>
                                                                    <td>{formData.district}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b>Correspondence Address:</b></td>
                                                                    <td>{formData.correspondence_address}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b>Correspondence Pincode</b></td>
                                                                    <td>{formData.pincode_c}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b>Correspondence City</b></td>
                                                                    <td>{formData.city_c}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b> Correspondence District</b></td>
                                                                    <td>{formData.district_c}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b> Address Proof:</b></td>
                                                                    <td>{formData.address_proof}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b>Uploaded Proof:</b></td>
                                                                    <td>
                                                                        {fileNames.upload_address &&
                                                                            fileNames.upload_address.toLowerCase().endsWith('.pdf') ? (
                                                                            <Worker
                                                                                workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
                                                                            >
                                                                                <div className={styles.movie}>
                                                                                    <Viewer fileUrl={imagePreviews.upload_address}>
                                                                                    </Viewer>
                                                                                </div>
                                                                            </Worker>
                                                                        ) : (
                                                                            <img
                                                                                className={styles.my_preview_container_single_image}
                                                                                src={imagePreviews.upload_address}
                                                                                alt="Upload Preview"
                                                                            />
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b>Uploaded Proof Back:</b></td>
                                                                    <td>
                                                                        {fileNames.upload_address_back &&
                                                                            fileNames.upload_address_back.toLowerCase().endsWith('.pdf') ? (
                                                                            <Worker
                                                                                workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
                                                                            >
                                                                                <div className={styles.movie}>
                                                                                    <Viewer fileUrl={imagePreviews.upload_address_back}>
                                                                                    </Viewer>
                                                                                </div>
                                                                            </Worker>
                                                                        ) : (
                                                                            <img
                                                                                className={styles.my_preview_container_single_image}
                                                                                src={imagePreviews.upload_address_back}
                                                                                alt="Upload Preview"
                                                                            />
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b>ITR Proof:</b></td>
                                                                    <td>
                                                                        {/* {imagePreviews.itr && (
                                                                            <div className={styles.my_preview_container_image}>
                                                                                <img
                                                                                    className={styles.my_preview_container_single_image}
                                                                                    src={imagePreviews.itr}
                                                                                    alt="ITR Preview : Due to the large file size, a preview is not available at the moment. However, your file has been successfully uploaded."
                                                                                />
                                                                            </div>
                                                                        )} */}

                                                                        {fileNames.itr &&
                                                                            fileNames.itr.toLowerCase().endsWith('.pdf') ? (
                                                                            <Worker
                                                                                workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
                                                                            >
                                                                                <div className={styles.movie}>
                                                                                    <Viewer fileUrl={imagePreviews.itr}>
                                                                                    </Viewer>
                                                                                </div>
                                                                            </Worker>
                                                                        ) : (
                                                                            <img
                                                                                className={styles.my_preview_container_single_image}
                                                                                src={imagePreviews.itr}
                                                                                alt="Upload Preview"
                                                                            />
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b>PAN Proof:</b></td>
                                                                    <td>
                                                                        {/* {imagePreviews.pan && (
                                                                            <div className={styles.my_preview_container_image}>
                                                                                <img
                                                                                    className={styles.my_preview_container_single_image}
                                                                                    src={imagePreviews.pan}
                                                                                    alt="PAN Preview : Due to the large file size, a preview is not available at the moment. However, your file has been successfully uploaded."
                                                                                />
                                                                            </div>
                                                                        )} */}
                                                                        {fileNames.pan &&
                                                                            fileNames.pan.toLowerCase().endsWith('.pdf') ? (
                                                                            <Worker
                                                                                workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
                                                                            >
                                                                                <div className={styles.movie}>
                                                                                    <Viewer fileUrl={imagePreviews.pan}>
                                                                                    </Viewer>
                                                                                </div>
                                                                            </Worker>
                                                                        ) : (
                                                                            <img
                                                                                className={styles.my_preview_container_single_image}
                                                                                src={imagePreviews.pan}
                                                                                alt="Upload Preview"
                                                                            />
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b>Bank Statement Proof:</b></td>
                                                                    <td>
                                                                        {/* {imagePreviews.bank_statement && (
                                                                            <div className={styles.my_preview_container_image}>
                                                                                <img
                                                                                    className={styles.my_preview_container_single_image}
                                                                                    src={imagePreviews.bank_statement}
                                                                                    alt="Bank Statement Preview : Due to the large file size, a preview is not available at the moment. However, your file has been successfully uploaded."
                                                                                />
                                                                            </div>
                                                                        )} */}
                                                                        {fileNames.bank_statement &&
                                                                            fileNames.bank_statement.toLowerCase().endsWith('.pdf') ? (
                                                                            <Worker
                                                                                workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
                                                                            >
                                                                                <div className={styles.movie}>
                                                                                    <Viewer fileUrl={imagePreviews.bank_statement}>
                                                                                    </Viewer>
                                                                                </div>
                                                                            </Worker>
                                                                        ) : (
                                                                            <img
                                                                                className={styles.my_preview_container_single_image}
                                                                                src={imagePreviews.bank_statement}
                                                                                alt="Upload Preview"
                                                                            />
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b>
                                                                        {
                                                                            formData.employee_type === "Salaried" ?
                                                                                "Salary Slip Proof:" : "GST Certificate Proof"
                                                                        }
                                                                    </b></td>
                                                                    <td>
                                                                        {/* {imagePreviews.salary_slip && (
                                                                            <div className={styles.my_preview_container_image}>
                                                                                <img
                                                                                    className={styles.my_preview_container_single_image}
                                                                                    src={imagePreviews.salary_slip}
                                                                                    alt="Salary Slip Preview : Due to the large file size, a preview is not available at the moment. However, your file has been successfully uploaded."
                                                                                />
                                                                            </div>
                                                                        )} */}

                                                                        {fileNames.salary_slip &&
                                                                            fileNames.salary_slip.toLowerCase().endsWith('.pdf') ? (
                                                                            <Worker
                                                                                workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
                                                                            >
                                                                                <div className={styles.movie}>
                                                                                    <Viewer fileUrl={imagePreviews.salary_slip}>
                                                                                    </Viewer>
                                                                                </div>
                                                                            </Worker>
                                                                        ) : (
                                                                            <img
                                                                                className={styles.my_preview_container_single_image}
                                                                                src={imagePreviews.salary_slip}
                                                                                alt="Upload Preview"
                                                                            />
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>

                                                {/* BUTTONS CONTAINER */}
                                                <div className={styles.final_button_container}>
                                                    <button
                                                        className={styles.back_button}
                                                        type="button"
                                                        onClick={handlePrevStage}
                                                    >
                                                        Back
                                                    </button>

                                                    <button
                                                        className={styles.submit_button}
                                                        type='submit'
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </>
                                        )
                                    }

                                </form>

                            </div>
                        )
                }
            </div>
        </>
    )
}

export default LoanApplication