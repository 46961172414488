import React from 'react'
import styles from '../LoanAppplication/LoanApplication.module.css';
import { useState, useEffect } from 'react';
import thank_you from '../../../../images/forms/consumer/consumer_thank_you.svg'
import axios from 'axios';
import { Box, Modal } from '@mui/material';
import { jwtDecode } from 'jwt-decode';
import { IoEye, IoEyeOff, IoEyeOffOutline, IoEyeOutline, IoEyeSharp } from "react-icons/io5";
import { GoUpload } from 'react-icons/go';
import { MdDeleteOutline, MdKeyboardArrowDown, MdOutlineDeleteOutline, MdOutlineMail } from "react-icons/md";
import { IoCloseCircleSharp, IoCloseSharp } from "react-icons/io5";
import { RxCross1 } from "react-icons/rx";
import oops_image from '../../../../images/loading/oops.svg'
import bar from '../../../../images/vectors/loan_eligibility/bar.svg'
import check from '../../../../images/vectors/loan_eligibility/check.svg'
import {
    MenuItem,
    InputLabel,
    FormControl,
    makeStyles,
    Select,
} from '@material-ui/core';
import { FiPhone } from "react-icons/fi";
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import PdfViewer from '../../../../reusable/pdfViewer/PdfViewer';
import useStyles from '../../../../reusable/DropdownStyle/useStyles';
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
).toString();


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "none",
    border: "none",
};




const LoanApplication2 = () => {
    const [stage, setStage] = useState(3);
    const [showPassword, setShowPassword] = useState(false);
    const [loanData, setLoanData] = useState();
    const classes = useStyles();
    const [openMUIModal, setOpenMUIModal] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const handleOpen = () => setOpenMUIModal(true);
    const handleClose = () => setOpenMUIModal(false);
    const [showMorePoints, setShowMorePoints] = useState(false);
    const [emptyFields, setEmptyFields] = useState([]);
    const [copyAddress, setCopyAddress] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [numPages, setNumPages] = useState();
    const [leadId, setLeadId] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const token = localStorage.getItem('Authorization');
    const stored_id = localStorage.getItem('my_consumer_stored_id')
    const consumer_id = localStorage.getItem('consumer_id')
    const my_dealer_id = localStorage.getItem('my-dealer-id');
    const credit_score = localStorage.getItem('credit_score');
    const credit_file = localStorage.getItem('credit_file');
    const buyer_name = localStorage.getItem('buyer_name');
    const buyer_EMI = localStorage.getItem('buyer_EMI');
    const buyer_salary = localStorage.getItem('buyer_salary');
    const buyer_phone = localStorage.getItem('buyer_phone');
    const buyer_pincode = localStorage.getItem('buyer_pincode');
    const indianPincodeRegex = /^[1-9][0-9]{5}$/;
    const info = jwtDecode(token);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    const handleInputFocus = () => {
        setIsFocused(true);
    };
    const handleInputBlur = () => {
        setIsFocused(false);
    };
    const [fileNames, setFileNames] = useState({
        address_front: "",
        address_back: "",
        pan: "",
        itr: "",
        bank_statement: "",
        bank_statement_password: "",
        salary_slip: "",
        utility_bill: "",
        rent_agreement: "",
        rc_front: "",
        rc_back: "",
        lead_id: '',
    });

    const [formData, setFormData] = useState({
        // Marketing Purpose
        utm_source: "Website",
        page: "consumer-login-page",

        consumer_id: info.consumer_id,
        full_name: '',
        email: '',
        monthly_income: '',
        loan_amount: '',
        existing_emi: '',
        pan: '',
        consent: "1",
        mobile: '',
        employee_type: '',
        house: '',
        vehicle_no: '',
        car_services: {
            extended_warranty: 'false',
            car_insurance: 'false',
            loan: 'true',
        },
    });

    const [imagePreviews, setImagePreviews] = useState({
        upload_address: '',
        upload_address_back: '',
        itr: '',
        pan: '',
        salary_slip: '',
        bank_statement: '',
        rc_front: '',
        rc_back: '',
        rent: '',
        utility_bill: '',
    });

    const [showUploadAddressPreview, setShowUploadAddressPreview] = useState(false);
    const [showUploadAddressPreview1, setShowUploadAddressPreview1] = useState(false);
    const [showRCFront, setshowRCFront] = useState(false);
    const [showRCBack, setshowRCBack] = useState(false);
    const [showRent, setShowRent] = useState(false);
    const [showUtilityBill, setShowUtilityBill] = useState(false);
    const [showItrPreview, setShowItrPreview] = useState(false);
    const [showPanPreview, setShowPanPreview] = useState(false);
    const [showSalarySlipPreview, setShowSalarySlipPreview] = useState(false);
    const [showBankStatementPreview, setShowBankStatementPreview] = useState(false);

    const handleInputChange = (e, inputName) => {
        let newValue = e.target.files ? e.target.files[0] : e.target.value;
        if (inputName === 'pan') {
            newValue = newValue.toUpperCase();
        }

        setFormData((prevFormData) => ({
            ...prevFormData,
            [inputName]: newValue,
        }));
    };

    const toggleCheckbox = (service) => {
        setFormData(prevState => ({
            ...prevState,
            car_services: {
                ...prevState.car_services,
                [service]: prevState.car_services[service] === 'true' ? 'false' : 'true'
            }
        }));
    };

    const handleFileUpload = async (e, fieldName) => {
        try {
            const file = e.target.files[0];

            if (file) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = (readerEvent) => {
                    setImagePreviews((prevPreviews) => ({
                        ...prevPreviews,
                        [fieldName]: readerEvent.target.result,
                    }));
                };
            }

            const files = e?.target?.files[0]
            const formData = new FormData();
            formData.append('files', files);

            const response = await axios.post(`/imageUpload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `${token}`,
                },
            })

            const shortenedFileName = response?.data?.file_name;
            // const [, shortenedFileName] = originalFileName.split('_');

            setFileNames((prevFileNames) => ({
                ...prevFileNames,
                [fieldName]: shortenedFileName,
            }));

        } catch (error) {
            console.error('Error uploading file:', error?.message);
        }
    };

    const pan_regex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/gm;
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const validateStage1 = () => {
        return (
            emailPattern.test(formData.email) &&
            pan_regex.test(formData.pan) &&
            formData.full_name !== '' &&
            formData.mobile !== '' &&
            formData.loan_amount !== '' &&
            formData.employee_type !== '' &&
            formData.house !== '' &&
            formData.monthly_income !== '' &&
            formData.pan !== '' &&
            formData.existing_emi !== ''
        );
    };

    const validateStage3 = () => {
        return (
            fileNames.bank_statement_password !== '' &&
            fileNames.rc_front !== '' &&
            fileNames.rc_back !== '' &&
            fileNames.address_front !== '' &&
            fileNames.address_back !== '' &&
            fileNames.pan !== '' &&
            fileNames.bank_statement !== '' &&
            (fileNames.salary_slip !== '' || fileNames.itr !== '') &&
            (fileNames.rent_agreement !== '' || fileNames.utility_bill !== '')
        );
    };

    const handleNextStage = async (e) => {
        e.preventDefault()
        const emptyFields = [];

        if (stage === 1 && !validateStage1()) {
            emptyFields.push("full_name", "employee_type", "house", "mobile", "loan_amount");
        }
        if (formData.pan === '') {
            emptyFields.push("pan");
        }

        if (stage === 1) {
            try {
                const response = await axios.post(`/loanApplication`, formData, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `${token}`,
                    },
                });

                const lead_id = response?.data?.lead_id;

                if (response?.data?.lead_id) {
                    setLeadId(lead_id);
                    setFileNames(prevFileNames => ({ ...prevFileNames, lead_id }));

                    try {
                        // const response = await axios.get(`${BACKEND_URL}/loan_eligibility?lead_id=${lead_id}`, {
                        //     headers: {
                        //         'Content-Type': 'application/json',
                        //         Authorization: `${token}`,
                        //     },
                        // })
                        setLoanData(response?.data);
                        setStage(stage + 2)
                    } catch (error) {
                        setStage(stage + 2)
                        console.log('Error in Loan Eligibility ->', error);
                    }
                }
            } catch (error) {
                console.error('Error Stage 1:', error);
            }
        }

        if (stage === 2) {
            setStage(stage + 1)
        }

        if (stage === 3 && !validateStage3()) {
            emptyFields.push("rc_front", "rc_back", "address_front",
                "address_back", "bank_statement", "bank_statement_password");

            if (fileNames.rent_agreement === '' && fileNames.utility_bill === '') {
                emptyFields.push("rent_agreement", "utility_bill");
            }
            if (fileNames.pan === '') {
                emptyFields.push("pan");
            }
            if (fileNames.salary_slip === '' && fileNames.itr === '') {
                emptyFields.push("salary_slip", "itr");
            }
        }


        if (stage === 3) {
            try {
                const imageFiles = {
                    ...fileNames,
                }
                const response = await axios.post(`/lead_docs`, imageFiles, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `${token}`,
                    },
                });
                handleSubmit(e);

            } catch (error) {
                console.error('Error calling Stage 3 API:', error.message);
            }
        }

        setEmptyFields(emptyFields);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsFormSubmitted(true);
            setFileNames({
                address_front: "",
                address_back: "",
                pan: "",
                itr: "",
                bank_statement: "",
                bank_statement_password: "",
                salary_slip: "",
                gst: "",
                utility_bill: "",
                rent_agreement: "",
                rc_front: "",
                rc_back: ""
            })

            setFormData({
                // id: stored_id,
                consumer_id: info?.consumer_id,
                utm_source: "Website",
                page: "consumer-login-page",
                full_name: '',
                email: '',
                employee_type: '',
                loan_amount: '',
                existing_emi: '',
                house: '',
                vehicle_no: '',
                permanent_address: '',
                correspondence_address: '',
                consent: "1",
                address_proof: '',
                salary: '',
                pan: '',

                mobile: info?.mobile,
                credit_score: credit_score,
                credit_file: credit_file,
            })
            handleOpen();

            const timerId2 = setTimeout(() => {
                localStorage.removeItem('my_consumer_stored_id')
            }, 0.1);
            return () => {
                clearTimeout(timerId2);
            };

        } catch (error) {
            console.error('Error submitting form:', error.message);
        }
    };

    const handlePrevStage = () => {
        setStage(stage - 2);
    };

    const indianFinanceNumber = (number) => {
        const formatter = new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            maximumFractionDigits: 2,
        });

        return formatter.format(number);
    };

    const handleViewMoreClick = () => {
        setShowMorePoints(!showMorePoints);
    };

    const handleDelete = (fileType) => {
        setFileNames((prevFileNames) => ({
            ...prevFileNames,
            [fileType]: '',
        }));
        setImagePreviews((prevImagePreviews) => ({
            ...prevImagePreviews,
            [fileType]: null,
        }));
    };

    const refreshButton = () => {
        window.location.reload();
        localStorage.removeItem('my_consumer_stored_id');
        setFormData({
            full_name: '',
            mother_name: '',
            email: '',
            employee_type: '',
            house: '',
        })
    }

    const handleRefresh = () => {
        setStage(stage - 1)
    }

    const truncateFileName = (fileName, maxLength = 15) => fileName.length > maxLength ? fileName.slice(0, maxLength - 4) + '.' + fileName.slice(fileName.lastIndexOf('.')) : fileName;


    return (
        <>
            <div className={styles.container}>
                {
                    isFormSubmitted ?
                        (
                            <div className={styles.successContainer}>
                                <img src={thank_you} alt="Success" />
                                <div className={styles.my_close_button} onClick={refreshButton}>
                                    <RxCross1 />
                                </div>
                            </div>
                        ) : (
                            <div className={styles.right}>

                                <form onSubmit={handleSubmit}>

                                    {
                                        stage === 1 && (
                                            <>
                                                {/* WELCOME TEXT */}
                                                <div className={styles.welcome_text}>
                                                    <div className={styles.welcome_text1}>
                                                        Loan Application Form 1
                                                    </div>
                                                    <div className={styles.welcome_text2}>
                                                        Please fill out all the details
                                                    </div>
                                                </div>
                                                <div className={styles.form}>
                                                    <div className={styles.col}>

                                                        {/* NAME */}
                                                        <div className={styles.MUI_input}>
                                                            <input
                                                                className={formData.full_name?.length > 0 ? styles.input_field1 : styles.input_field}
                                                                type="text"
                                                                value={formData.full_name}
                                                                onChange={(e) => handleInputChange(e, 'full_name')}
                                                                required
                                                                onKeyDown={(e) => {
                                                                    const keyPressed = e.key;
                                                                    const isValidInput = /^[A-Za-z\s]*$/.test(keyPressed);
                                                                    const isNavigationKey = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Delete', 'Backspace'].includes(keyPressed);
                                                                    const isCtrlKey = e.ctrlKey || e.metaKey;
                                                                    const isCopyPaste = isCtrlKey && ['a', 'c', 'v'].includes(keyPressed.toLowerCase());
                                                                    if (!isValidInput && !isNavigationKey && !isCopyPaste) {
                                                                        e.preventDefault();
                                                                    }
                                                                }}
                                                            />
                                                            <div className={`${styles.float_label} ${formData.full_name && styles.labelActive}`}>
                                                                Name of Customer
                                                                <span style={{ color: `var(--red)` }}> *</span>
                                                            </div>
                                                        </div>
                                                        {emptyFields.includes("full_name") && formData.full_name === "" && (
                                                            <p className={styles.error}>Full Name is required</p>
                                                        )}

                                                        {/* MOBILE */}
                                                        <div className={styles.MUI_input}>
                                                            <div className={`${styles.phoneNumberDisplay} ${formData.mobile && styles.phoneNumberActive}`}>
                                                                +91
                                                            </div>
                                                            <input
                                                                // className={formData.mobile?.length > 0 ? styles.input_field1 : styles.input_field}
                                                                className={`${styles.input_field} ${formData?.mobile?.length > 0 ? styles.input_field2 : ''} ${isFocused ? styles.input_field2 : ''}`}
                                                                style={{ padding: "12px 0px" }}
                                                                type="tel"
                                                                pattern='^(\+91[\-\s]?)?[789]\d{9}$'
                                                                value={formData.mobile}
                                                                maxLength={10}
                                                                minLength={10}
                                                                onChange={(e) => handleInputChange(e, 'mobile')}
                                                                required
                                                            />
                                                            <div className={`${styles.float_label} ${formData.mobile && styles.labelActive}`}>
                                                                {isFocused ? "Mobile Number" : formData.mobile.length === 0 ? "Mobile Number " : "Mobile Number "} <span style={{ color: `var(--red)` }}> *</span>
                                                            </div>
                                                        </div>
                                                        {emptyFields.includes("mobile") && formData.mobile === "" && (
                                                            <p className={styles.error}>Mobile is required</p>
                                                        )}
                                                        {!emptyFields.includes("mobile") && formData.mobile !== "" && !/^(\+91[\-\s]?)?[789]\d{9}$/.test(formData.mobile) && (
                                                            <p className={styles.error}>Invalid mobile format</p>
                                                        )}

                                                        {/* LOAN AMOUNT */}
                                                        <div className={styles.MUI_input}>
                                                            <input
                                                                className={formData.loan_amount?.length > 0 ? styles.input_field1 : styles.input_field}
                                                                type="number"
                                                                value={formData.loan_amount}
                                                                onChange={(e) => handleInputChange(e, 'loan_amount')}
                                                                required
                                                            />
                                                            <div className={`${styles.float_label} ${formData.loan_amount && styles.labelActive}`}
                                                            >
                                                                Loan Amount to be Financed <span style={{ color: `var(--red)` }}>*</span>
                                                            </div>
                                                        </div>
                                                        {emptyFields.includes("loan_amount") && formData.loan_amount === "" && (
                                                            <p className={styles.error}>Loan Amount is required</p>
                                                        )}

                                                        {/* DROPDOWN */}
                                                        <div className={styles.MUI_input}>
                                                            <div className={styles.option_container}>
                                                                <FormControl
                                                                    variant="outlined"
                                                                    fullWidth
                                                                >
                                                                    <InputLabel
                                                                        className={classes.label}
                                                                        id="demo-simple-select-outlined-label"
                                                                    >
                                                                        Employment Type
                                                                        <span style={{ color: 'red' }}> *</span>
                                                                    </InputLabel>
                                                                    <Select
                                                                        sx={{ "& .MuiSvgIcon-root": { color: "red" } }}
                                                                        labelId="demo-simple-select-outlined-label"
                                                                        id="demo-simple-select-outlined"
                                                                        value={formData.employee_type}
                                                                        onChange={(e) => handleInputChange(e, 'employee_type')}
                                                                        label="Employment Type Type"
                                                                        renderValue={(selected) => <span className={classes.selectedOption}>{selected}</span>}
                             
                                                                        className={`${classes.select} ${formData.employee_type?.length > 0 ? classes.filled : ''
                                                                            }`}
                                                                        MenuProps={{
                                                                            anchorOrigin: {
                                                                                vertical: 'bottom',
                                                                                horizontal: 'left',
                                                                            },
                                                                            transformOrigin: {
                                                                                vertical: 'top',
                                                                                horizontal: 'left',
                                                                            },
                                                                            getContentAnchorEl: null,
                                                                            autowidth: 'false',
                                                                        }}
                                                                        menulistprops={{
                                                                            autoFocus: true,
                                                                        }}
                                                                        onFocus={() => setIsFocused(true)} // Update the focus state when the Select receives focus
                                                                        onBlur={() => setIsFocused(false)}
                                                                    >
                                                                        <MenuItem
                                                                            value="Salaried"
                                                                            className={`${classes.option}`}>
                                                                            Salaried
                                                                        </MenuItem>
                                                                        <MenuItem value="Self Employed" className={`${classes.option}`}>
                                                                            Self Employed
                                                                        </MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                        {emptyFields.includes("employee_type") && formData.employee_type === "" && (
                                                            <p className={styles.error}>Employment Type is required</p>
                                                        )}

                                                        {/* DROPDOWN  */}
                                                        <div className={styles.MUI_input}>
                                                            <div className={styles.option_container}>
                                                                <FormControl variant="outlined" fullWidth>
                                                                    <InputLabel
                                                                        className={classes.label}
                                                                        id="demo-simple-select-outlined-label"
                                                                    >
                                                                        Home Ownership
                                                                        <span style={{ color: 'red' }}> *</span>
                                                                    </InputLabel>
                                                                    <Select
                                                                        sx={{
                                                                            outline: 'none',
                                                                            boxShadow: 'none',
                                                                            '.MuiOutlinedInput-notchedOutline': { border: 0 }
                                                                        }}
                                                                        labelId="demo-simple-select-outlined-label"
                                                                        id="demo-simple-select-outlined"
                                                                        value={formData.house}
                                                                        onChange={(e) => handleInputChange(e, 'house')}
                                                                        label="Home Ownership hom"
                                                                        renderValue={(selected) => <span className={classes.selectedOption}>{selected}</span>}
                                                                        
                                                                        className={`${classes.select} ${formData.house?.length > 0 ? classes.filled : ''
                                                                            }`}
                                                                        MenuProps={{
                                                                            anchorOrigin: {
                                                                                vertical: 'bottom',
                                                                                horizontal: 'left',
                                                                            },
                                                                            transformOrigin: {
                                                                                vertical: 'top',
                                                                                horizontal: 'left',
                                                                            },
                                                                            getContentAnchorEl: null,
                                                                            autowidth: 'true',
                                                                        }}
                                                                    >
                                                                        <MenuItem
                                                                            value="Rented"
                                                                            className={`${classes.option}`}>
                                                                            Rented
                                                                        </MenuItem>
                                                                        <MenuItem value="Owned" className={`${classes.option}`}>
                                                                            Owned
                                                                        </MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                        {emptyFields.includes("house") && formData.house === "" && (
                                                            <p className={styles.error}>House is required</p>
                                                        )}

                                                        {/* PANCARD */}
                                                        <div className={styles.MUI_input}>
                                                            <input
                                                                className={formData.pan?.length > 0 ? styles.input_field1 : styles.input_field}
                                                                type="text"
                                                                maxLength={10}
                                                                value={formData.pan}
                                                                onChange={(e) => handleInputChange(e, 'pan')}
                                                                required
                                                            />
                                                            <div className={`${styles.float_label} ${formData.pan && styles.labelActive}`}
                                                            >
                                                                PAN Number <span style={{ color: `var(--red)` }}> *</span>
                                                            </div>
                                                        </div>
                                                        {emptyFields.includes("pan") && formData.pan === "" && (
                                                            <p className={styles.error}>PAN is required</p>
                                                        )}
                                                        {!emptyFields.includes("pan") && formData.pan !== "" && !pan_regex.test(formData.pan) && (
                                                            <p className={styles.error}>Invalid PAN format</p>
                                                        )}

                                                        {/* SALARY */}
                                                        <div className={styles.MUI_input}>
                                                            <input
                                                                className={formData.monthly_income?.length > 0 ? styles.input_field1 : styles.input_field}
                                                                type="number"
                                                                value={formData.monthly_income}
                                                                onChange={(e) => handleInputChange(e, 'monthly_income')}
                                                                required
                                                            />
                                                            <div className={`${styles.float_label} ${formData.monthly_income && styles.labelActive}`}
                                                            >
                                                                Monthly Income
                                                                {/* <span style={{ color: `var(--red)` }}>*</span> */}
                                                            </div>
                                                        </div>
                                                        {emptyFields.includes("monthly_income") && formData.monthly_income === "" && (
                                                            <p className={styles.error}>Monthly Income </p>
                                                        )}

                                                        {/* EMI */}
                                                        <div className={styles.MUI_input}>
                                                            <input
                                                                className={formData.existing_emi?.length > 0 ? styles.input_field1 : styles.input_field}
                                                                type="number"
                                                                value={formData.existing_emi}
                                                                onChange={(e) => handleInputChange(e, 'existing_emi')}
                                                                required
                                                            />
                                                            <div className={`${styles.float_label} ${formData.existing_emi && styles.labelActive}`}
                                                            >
                                                                Any Existing EMI ?
                                                                {/* <span style={{ color: `var(--red)` }}>*</span> */}
                                                            </div>
                                                        </div>
                                                        {emptyFields.includes("existing_emi") && formData.existing_emi === "" && (
                                                            <p className={styles.error}>Existing EMI is required</p>
                                                        )}

                                                        {/* VECHILE NUMBER  */}
                                                        <div className={styles.MUI_input}>
                                                            <input
                                                                required
                                                                className={formData.vehicle_no?.length > 0 ? styles.input_field1 : styles.input_field}
                                                                type="text"
                                                                value={formData.vehicle_no}
                                                                onChange={(e) => handleInputChange(e, 'vehicle_no')}
                                                                pattern='^[A-Z]{2}[0-9]{1,2}(?:[A-Z])?(?:[A-Z]*)?[0-9]{4}$'
                                                                maxLength={10}
                                                                title='FORMAT DL AE 01 2321'
                                                                onInput={(e) => {
                                                                    e.target.value = e.target.value.toUpperCase();
                                                                }}
                                                            />
                                                            <div className={`${styles.float_label} ${formData.vehicle_no && styles.labelActive}`}
                                                            >
                                                                Vehicle Number
                                                                {/* <span className={styles.vehicle_no_avilable}>
                                                                    (If Available)
                                                                </span> */}
                                                            </div>
                                                        </div>
                                                        {emptyFields.includes('vehicle_no') && formData.vehicle_no === '' && (
                                                            <p className={styles.error}>
                                                                Vehicle Number is required</p>
                                                        )}

                                                        {formData.vehicle_no && !/^[A-Z]{2}[0-9]{1,2}(?:[A-Z])?(?:[A-Z]*)?[0-9]{4}$/.test(formData.vehicle_no) && (
                                                            <p className={styles.error}>
                                                                Please provide a valid Indian vehicle number
                                                            </p>
                                                        )}

                                                        {/* ADD CAR SERVICES */}
                                                        <>
                                                            <div className={styles.sub_heading}>
                                                                Add Car Services
                                                            </div>
                                                            <div className={styles.flex}>
                                                                <div className={styles.consent_left}>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={formData.car_services.car_insurance === 'true'}
                                                                        onChange={() => toggleCheckbox('car_insurance')}
                                                                        required
                                                                    />
                                                                </div>
                                                                <div className={styles.consent_right}>
                                                                    Insurance
                                                                </div>
                                                            </div>
                                                            <div className={styles.flex}>
                                                                <div className={styles.consent_left}>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={formData.car_services.extended_warranty === 'true'}
                                                                        onChange={() => toggleCheckbox('extended_warranty')}
                                                                        required
                                                                    />
                                                                </div>
                                                                <div className={styles.consent_right}>
                                                                    Extended Warranty
                                                                </div>
                                                            </div>
                                                        </>
                                                    </div>
                                                </div>

                                                <div className={styles.final_button_container1}>
                                                    <button
                                                        className={styles.submit_button1}
                                                        onClick={handleNextStage}
                                                    >
                                                        Next
                                                    </button>
                                                </div>
                                            </>
                                        )
                                    }

                                    {
                                        stage === 2 && (
                                            <>
                                                {
                                                    ((loanData === undefined || loanData?.score === null || loanData?.score === undefined || loanData?.eligibility === 0)
                                                        || loanData?.score < 0 ||
                                                        (loanData?.score >= 1 && loanData?.score < 650))
                                                        ?
                                                        <>
                                                            <div className={styles.illustration_img}>
                                                                <img src={oops_image} alt="oops_image" />
                                                            </div>
                                                            <div className={styles.guide}>
                                                                <div className={styles.point1}>
                                                                    Thank you for using our loan eligibility Calculator.
                                                                    <br /> Unfortunately, your credit score doesn't meet our current loan criteria
                                                                </div>
                                                                <div className={styles.point2}>
                                                                    Here's a brief guide to help improve your <span style={{ color: `var(--teal)` }} >credit score</span>
                                                                </div>
                                                                <div className={styles.more_points}>
                                                                    <ul>
                                                                        <li>
                                                                            1. Check Your Credit Report:
                                                                            <br />
                                                                            &nbsp;&nbsp;&nbsp; Review for errors and dispute inaccuracies
                                                                        </li>
                                                                        <li>
                                                                            2. Pay Bills on Time:
                                                                            <br />
                                                                            &nbsp;&nbsp;&nbsp; Set up reminders or automatic payments
                                                                        </li>
                                                                        <li>
                                                                            3. Reduce Credit Card Balances:
                                                                            <br />
                                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Lower outstanding balances for a healthier credit utilization ratio.
                                                                        </li>
                                                                    </ul>
                                                                    <div className={styles.view_more} onClick={handleViewMoreClick}>
                                                                        {showMorePoints ? 'View Less >>>' : 'View More >>>> '}
                                                                    </div>
                                                                    {
                                                                        showMorePoints && (
                                                                            <ul>

                                                                                <li>
                                                                                    4. Create a Budget:
                                                                                    <br />
                                                                                    &nbsp;&nbsp;&nbsp; Manage finances effectively to prioritize debts.
                                                                                </li>
                                                                                <li>
                                                                                    5. Avoid Opening New Credit Accounts:
                                                                                    <br />
                                                                                    &nbsp;&nbsp;&nbsp; Limit new applications to maintain your score.
                                                                                </li>
                                                                                <li>
                                                                                    6. Build a Positive Credit History:
                                                                                    <br />
                                                                                    &nbsp;&nbsp;&nbsp; Use credit responsibly over time.
                                                                                </li>
                                                                                <li>
                                                                                    7. Seek Professional Advice:
                                                                                    <br />
                                                                                    &nbsp;&nbsp;&nbsp;&nbsp;Consult financial advisors or credit counselling services for personalized guidance.
                                                                                </li>
                                                                            </ul>
                                                                        )
                                                                    }
                                                                    <div className={styles.flex1}>
                                                                        <div className={styles.apply_loan_button} onClick={handleRefresh}>
                                                                            Back
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div className={styles.main_form}>
                                                                <div className={styles.loan_bar1}>
                                                                    <img src={check} alt="" />
                                                                </div>
                                                                <div
                                                                    className={styles.mui_heading1}
                                                                >
                                                                    Congratulations
                                                                </div>
                                                                <div
                                                                    className={styles.mui_sub_heading1}
                                                                >
                                                                    {formData.full_name} You are eligible for an approval of
                                                                </div>


                                                                <div className={styles.score}>
                                                                    {
                                                                        (loanData.eligibility === undefined || loanData.eligibility < 0 || (loanData.eligibility >= 1 && loanData.eligibility <= 650))
                                                                            ? "You Are Not Eligible"
                                                                            : indianFinanceNumber(loanData.eligibility)
                                                                    }
                                                                    <br />
                                                                </div>
                                                                <div
                                                                    className={styles.mui_sub_heading1}
                                                                >
                                                                    With Good Credit Score of {loanData.score} points

                                                                </div>


                                                                <div className={styles.loan_bar2}
                                                                    style={{ padding: "10px 0" }}
                                                                >
                                                                    <img src={bar} alt="" />
                                                                </div>


                                                                <div className={styles.final_button_container1}>
                                                                    <div className={styles.apply_loan_button}>
                                                                        <button
                                                                            onClick={handleNextStage}>
                                                                            Next
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                }
                                            </>
                                        )
                                    }

                                    {
                                        stage === 3 && (
                                            <>
                                                {/* WELCOME TEXT */}
                                                <div className={styles.welcome_text}>
                                                    <div className={styles.welcome_text1}>
                                                        Loan Application Form
                                                    </div>
                                                    <div className={styles.welcome_text2}
                                                        style={{ marginBottom: "40px" }}
                                                    >
                                                        Please upload KYC details, salary slip, 1 year of ITR & 1 year of bank statement.
                                                        If you don’t have these documents now you can come back whenever you want and submit these documents or you can also contact us at
                                                        <a href="mailto:contact@nxcar.in" target="_blank">
                                                            {" "}  <MdOutlineMail color="var(--teal)" />
                                                            <span style={{ textDecoration: 'underLine' }}> contact@nxcar.in</span>
                                                        </a>
                                                        {" "} <FiPhone color="var(--teal)" />
                                                        <a href="https://wa.me/919355924132" target="_blank"> 9355924132</a>
                                                    </div>
                                                </div>
                                                <div className={styles.col}>
                                                    <>
                                                        {/* ITR OR SALARIED */}
                                                        <>
                                                            {
                                                                formData?.employee_type === "Salaried" ?
                                                                    (
                                                                        <>
                                                                            <div
                                                                                className={fileNames?.salary_slip?.length > 0 ? styles.my_upload_label : styles.none}
                                                                            >
                                                                                {(
                                                                                    formData?.employee_type === "Salaried" ?
                                                                                        'Salary Slip of Last 3 Months' :
                                                                                        'ITR of Last 2 Years'
                                                                                )}
                                                                            </div>
                                                                            <input
                                                                                type="file"
                                                                                id='customFileInput1'
                                                                                accept=".pdf, .jpg, .jpeg"
                                                                                required
                                                                                hidden
                                                                                onChange={(e) => handleFileUpload(e, 'salary_slip')}
                                                                            />
                                                                            <div className={styles.file_flex}>
                                                                                <label htmlFor="customFileInput1"
                                                                                    className={fileNames?.salary_slip?.length > 0 ? styles.customFileInput1_upload : styles.customFileInput1}
                                                                                >
                                                                                    <div className={styles.text}>
                                                                                        {fileNames?.salary_slip?.length > 0 ? (
                                                                                            truncateFileName(fileNames.salary_slip)
                                                                                        ) : (
                                                                                            formData.employee_type === "Salaried" ?
                                                                                                'Salary Slip of Last 3 Months *' :
                                                                                                'ITR of Last 2 Years'
                                                                                        )}
                                                                                    </div>
                                                                                    <div className={styles.image}>
                                                                                        {
                                                                                            fileNames?.salary_slip?.length == 0 ? <GoUpload /> : ""
                                                                                        }
                                                                                    </div>
                                                                                </label>
                                                                                <div className={styles.image}>
                                                                                    {
                                                                                        fileNames?.salary_slip !== "" ?
                                                                                            (
                                                                                                (
                                                                                                    <div className={styles.eye_icon_container}>
                                                                                                        <div
                                                                                                            className={styles.icon}
                                                                                                            onClick={() => setShowSalarySlipPreview(!showSalarySlipPreview)}>
                                                                                                            <IoEyeOutline size={24} />
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className={styles.icon}
                                                                                                            onClick={() => handleDelete('salary_slip')}
                                                                                                        >
                                                                                                            <MdOutlineDeleteOutline size={24} />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            )
                                                                                            :
                                                                                            (
                                                                                                null
                                                                                            )
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <Modal
                                                                                open={showSalarySlipPreview}
                                                                                onClose={() => setShowSalarySlipPreview(false)}
                                                                                aria-labelledby="upload-address-preview-modal"
                                                                                aria-describedby="upload-address-preview-description"
                                                                            >
                                                                                <div className={styles.preview}>
                                                                                    <button
                                                                                        className={styles.close_button_for_preview}
                                                                                        onClick={() => setShowSalarySlipPreview(false)}
                                                                                    >
                                                                                        <IoCloseCircleSharp />
                                                                                    </button>
                                                                                    {fileNames?.salary_slip &&
                                                                                        fileNames?.salary_slip.toLowerCase().endsWith('.pdf') ? (
                                                                                        <div
                                                                                            className={styles.modalImage}
                                                                                        >
                                                                                            {fileNames.salary_slip.startsWith('https') ? (
                                                                                                <iframe
                                                                                                    type="application/pdf"
                                                                                                    style={{ height: "100%", width: "100%" }}
                                                                                                    src={`https://docs.google.com/gview?url=${encodeURIComponent(fileNames.salary_slip_name)}&embedded=true`}
                                                                                                />
                                                                                            ) : (
                                                                                                <>
                                                                                                    {/* <Worker
                                                                                                        workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
                                                                                                    >
                                                                                                        <Viewer fileUrl={imagePreviews?.salary_slip} />
                                                                                                    </Worker> */}

                                                                                                    <PdfViewer pdfUrl={imagePreviews?.salary_slip} />
                                                                                                </>
                                                                                            )}

                                                                                        </div>
                                                                                    ) : (
                                                                                        <img
                                                                                            className={styles.modalImage}
                                                                                            src={imagePreviews?.salary_slip || fileNames?.salary_slip}
                                                                                            alt="Upload Preview 1"
                                                                                        />
                                                                                    )}
                                                                                </div>
                                                                            </Modal>
                                                                            {emptyFields.includes("salary_slip") && fileNames?.salary_slip === "" && (
                                                                                <p className={styles.error}>Salary Slip is required</p>
                                                                            )}
                                                                        </>
                                                                    ) :
                                                                    (
                                                                        <>
                                                                            {/* ITR FILE INPUT */}
                                                                            <div
                                                                                className={fileNames?.itr?.length > 0 ? styles.my_upload_label : styles.none}
                                                                            >
                                                                                ITR
                                                                            </div>
                                                                            <input
                                                                                type="file"
                                                                                id='customFileInput2'
                                                                                accept=".pdf, .jpg, .jpeg"
                                                                                required
                                                                                hidden
                                                                                onChange={(e) => handleFileUpload(e, 'itr')}
                                                                            />
                                                                            <div className={styles.file_flex}>
                                                                                <label htmlFor="customFileInput2"
                                                                                    className={fileNames?.itr?.length > 0 ? styles.customFileInput1_upload : styles.customFileInput1}
                                                                                >
                                                                                    <div className={styles.text}>
                                                                                        {
                                                                                            fileNames?.itr?.length > 0 ?
                                                                                                (
                                                                                                    truncateFileName(fileNames?.itr)
                                                                                                ) :
                                                                                                (
                                                                                                    'ITR of Last 2 Years *'
                                                                                                )
                                                                                        }
                                                                                    </div>
                                                                                    <div className={styles.image}>
                                                                                        {
                                                                                            fileNames?.itr?.length == 0 ? <GoUpload /> : ""
                                                                                        }
                                                                                    </div>
                                                                                </label>
                                                                                <div className={styles.image}>
                                                                                    {
                                                                                        fileNames?.itr !== "" ?
                                                                                            (
                                                                                                (
                                                                                                    <div className={styles.eye_icon_container}>
                                                                                                        <div
                                                                                                            className={styles.icon}
                                                                                                            onClick={() => setShowItrPreview(!showItrPreview)}>
                                                                                                            <IoEyeOutline size={24} />
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className={styles.icon}
                                                                                                            onClick={() => handleDelete('itr')}
                                                                                                        >
                                                                                                            <MdOutlineDeleteOutline size={24} />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            )
                                                                                            :
                                                                                            (
                                                                                                null
                                                                                            )
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <Modal
                                                                                open={showItrPreview}
                                                                                onClose={() => setShowItrPreview(false)}
                                                                                aria-labelledby="upload-address-preview-modal"
                                                                                aria-describedby="upload-address-preview-description"
                                                                            >
                                                                                <div className={styles.preview}>
                                                                                    <button
                                                                                        className={styles.close_button_for_preview}
                                                                                        onClick={() => setShowItrPreview(false)}
                                                                                    >
                                                                                        <IoCloseCircleSharp />
                                                                                    </button>
                                                                                    {fileNames?.itr &&
                                                                                        fileNames?.itr.toLowerCase().endsWith('.pdf') ? (
                                                                                        <div
                                                                                            className={styles.modalImage}
                                                                                        >
                                                                                            {fileNames.itr.startsWith('https') ? (
                                                                                                <iframe
                                                                                                    type="application/pdf"
                                                                                                    style={{ height: "100%", width: "100%" }}
                                                                                                    src={`https://docs.google.com/gview?url=${encodeURIComponent(fileNames.itr)}&embedded=true`}
                                                                                                />
                                                                                            ) : (
                                                                                                <>
                                                                                                    {/* <Worker
                                                                                                    workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
                                                                                                >
                                                                                                    <Viewer fileUrl={imagePreviews?.itr} />
                                                                                                </Worker> */}
                                                                                                    <PdfViewer pdfUrl={imagePreviews?.itr} />
                                                                                                </>
                                                                                            )}
                                                                                        </div>
                                                                                    ) : (
                                                                                        <>
                                                                                            <img
                                                                                                className={styles.modalImage}
                                                                                                src={imagePreviews?.itr || fileNames?.itr_name || fileNames?.itr}
                                                                                                alt="Upload Preview 2"
                                                                                            />
                                                                                        </>
                                                                                    )}

                                                                                </div>
                                                                            </Modal>
                                                                            {emptyFields.includes("itr") && fileNames?.itr === "" && (
                                                                                <p className={styles.error}>ITR is required</p>
                                                                            )}
                                                                        </>
                                                                    )
                                                            }
                                                        </>

                                                        {/* RENTED */}
                                                        <>
                                                            {
                                                                formData.house === 'Rented' ? (
                                                                    <>
                                                                        <div
                                                                            className={fileNames?.rent_agreement?.length > 0 ? styles.my_upload_label : styles.none}
                                                                        >
                                                                            Rent Agreement
                                                                        </div>
                                                                        <input
                                                                            type="file"
                                                                            id='customFileInput3'
                                                                            accept=".pdf, .jpg, .jpeg"
                                                                            required
                                                                            hidden
                                                                            onChange={(e) => handleFileUpload(e, 'rent_agreement')}
                                                                        />
                                                                        <div className={styles.file_flex}>
                                                                            <label
                                                                                htmlFor="customFileInput3"
                                                                                className={fileNames?.rent_agreement?.length > 0 ? styles.customFileInput1_upload : styles.customFileInput1}
                                                                            >
                                                                                <div className={styles.text}>
                                                                                    {
                                                                                        fileNames?.rent_agreement ?
                                                                                            (
                                                                                                truncateFileName(fileNames?.rent_agreement)
                                                                                            ) :
                                                                                            (
                                                                                                'Rent Agreement *'
                                                                                            )
                                                                                    }
                                                                                </div>
                                                                                <div className={styles.image}>
                                                                                    {
                                                                                        fileNames?.rent_agreement?.length == 0 ? <GoUpload /> : ""
                                                                                    }
                                                                                </div>
                                                                            </label>
                                                                            <div className={styles.image}>
                                                                                {
                                                                                    fileNames?.rent_agreement != '' ?
                                                                                        (
                                                                                            <div className={styles.eye_icon_container}>

                                                                                                <div
                                                                                                    className={styles.icon}
                                                                                                    onClick={() => setShowRent(!showRent)}>
                                                                                                    <IoEyeOutline size={24} />
                                                                                                </div>
                                                                                                <div
                                                                                                    className={styles.icon}
                                                                                                    onClick={() => handleDelete('rent_agreement')}
                                                                                                >
                                                                                                    <MdOutlineDeleteOutline size={24} />
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                        :
                                                                                        (
                                                                                            null
                                                                                        )
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <Modal
                                                                            open={showRent}
                                                                            onClose={() => setShowRent(false)}
                                                                            aria-labelledby="upload-address-preview-modal"
                                                                            aria-describedby="upload-address-preview-description"
                                                                        >
                                                                            <div className={styles.preview}>
                                                                                <button
                                                                                    className={styles.close_button_for_preview}
                                                                                    onClick={() => setShowRent(false)}
                                                                                >
                                                                                    <IoCloseCircleSharp />
                                                                                </button>
                                                                                {fileNames?.rent_agreement &&
                                                                                    fileNames?.rent_agreement.toLowerCase().endsWith('.pdf') ?
                                                                                    (
                                                                                        <div className={styles.modalImage}>
                                                                                            {fileNames.rent_agreement.startsWith('https') ? (
                                                                                                <iFrame
                                                                                                    style={{ height: "100%", width: "100%", color: 'white' }}
                                                                                                    type="application/pdf"
                                                                                                    src={`https://docs.google.com/gview?url=${encodeURIComponent(fileNames.rent_agreement)}&embedded=true`}
                                                                                                />
                                                                                            ) : (
                                                                                                <>
                                                                                                    {/* <Worker
                                                                                                        workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
                                                                                                    >
                                                                                                        <Viewer fileUrl={imagePreviews?.rent_agreement} />
                                                                                                    </Worker> */}

                                                                                                    <PdfViewer pdfUrl={imagePreviews?.rent_agreement} />
                                                                                                </>
                                                                                            )}
                                                                                        </div>
                                                                                    ) : (
                                                                                        <img
                                                                                            className={styles.modalImage}
                                                                                            src={imagePreviews?.rent_agreement || fileNames?.rent_agreement}
                                                                                            alt="Upload Preview 3"
                                                                                        />
                                                                                    )}
                                                                            </div>
                                                                        </Modal>
                                                                        {emptyFields.includes("rent_agreement") && fileNames?.rent_agreement === "" && (
                                                                            <p className={styles.error}>Rent Agreement is required</p>
                                                                        )}
                                                                    </>
                                                                ) : null
                                                            }
                                                        </>

                                                        {/* ELECTRICITY BILL / UTILITY BILL */}
                                                        <>
                                                            <div
                                                                className={fileNames?.utility_bill?.length > 0 ? styles.my_upload_label : styles.none}
                                                            >
                                                                {(
                                                                    (formData.house === "Rented" && (formData.employee_type === "Salaried" || formData.employee_type === "Self Employed")) ?
                                                                        'Owner Electricity Bill' :
                                                                        'Utility Bill'
                                                                )}
                                                            </div>
                                                            <input
                                                                type="file"
                                                                id='customFileInput4'
                                                                accept=".pdf, .jpg, .jpeg"
                                                                required
                                                                hidden
                                                                onChange={(e) => handleFileUpload(e, 'utility_bill')}
                                                            />
                                                            <div className={styles.file_flex}>
                                                                <label htmlFor="customFileInput4"
                                                                    className={fileNames?.utility_bill?.length > 0 ? styles.customFileInput1_upload : styles.customFileInput1}
                                                                >
                                                                    <div className={styles.text}>
                                                                        {fileNames?.utility_bill?.length > 0 ? (
                                                                            truncateFileName(fileNames.utility_bill)
                                                                        ) : (
                                                                            (formData.house === "Rented" && (formData.employee_type === "Salaried" || formData.employee_type === "Self Employed")) ?
                                                                                'Owner Electricity Bill *' :
                                                                                'Utility Bill *'
                                                                        )}
                                                                    </div>
                                                                    <div className={styles.image}>
                                                                        {
                                                                            fileNames?.utility_bill?.length == 0 ? <GoUpload /> : ""
                                                                        }
                                                                    </div>
                                                                </label>
                                                                <div className={styles.image}>
                                                                    {
                                                                        fileNames?.utility_bill !== "" ?
                                                                            (
                                                                                (
                                                                                    <div className={styles.eye_icon_container}>
                                                                                        <div
                                                                                            className={styles.icon}
                                                                                            onClick={() => setShowUtilityBill(!showUtilityBill)}>
                                                                                            <IoEyeOutline size={24} />
                                                                                        </div>
                                                                                        <div
                                                                                            className={styles.icon}
                                                                                            onClick={() => handleDelete('utility_bill')}
                                                                                        >
                                                                                            <MdOutlineDeleteOutline size={24} />
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            )
                                                                            :
                                                                            (
                                                                                null
                                                                            )
                                                                    }
                                                                </div>
                                                            </div>
                                                            <Modal
                                                                open={showUtilityBill}
                                                                onClose={() => setShowUtilityBill(false)}
                                                                aria-labelledby="upload-address-preview-modal"
                                                                aria-describedby="upload-address-preview-description"
                                                            >
                                                                <div className={styles.preview}>
                                                                    <button
                                                                        className={styles.close_button_for_preview}
                                                                        onClick={() => setShowUtilityBill(false)}
                                                                    >
                                                                        <IoCloseCircleSharp />
                                                                    </button>
                                                                    {fileNames?.utility_bill &&
                                                                        fileNames?.utility_bill.toLowerCase().endsWith('.pdf') ? (
                                                                        <div
                                                                            className={styles.modalImage}
                                                                        >
                                                                            {fileNames.utility_bill.startsWith('https') ? (
                                                                                <iframe
                                                                                    type="application/pdf"
                                                                                    style={{ height: "100%", width: "100%" }}
                                                                                    src={`https://docs.google.com/gview?url=${encodeURIComponent(fileNames.utility_bill_name)}&embedded=true`}

                                                                                />
                                                                            ) : (
                                                                                <>
                                                                                    {/* <Worker
                                                                                        workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
                                                                                    >
                                                                                        <Viewer fileUrl={imagePreviews?.utility_bill} />
                                                                                    </Worker> */}
                                                                                    <PdfViewer pdfUrl={imagePreviews?.utility_bill} />
                                                                                </>
                                                                            )}

                                                                        </div>
                                                                    ) : (
                                                                        <img
                                                                            className={styles.modalImage}
                                                                            src={imagePreviews?.utility_bill || fileNames?.utility_bill}
                                                                            alt="Upload Preview 4"
                                                                        />
                                                                    )}
                                                                </div>
                                                            </Modal>
                                                            {emptyFields.includes("utility_bill") && fileNames?.utility_bill === "" && (
                                                                <p className={styles.error}>Utitlity Bill is required</p>
                                                            )}
                                                        </>

                                                        {/* PAN CARD FILE INPUT */}
                                                        <>
                                                            <div
                                                                className={fileNames?.pan?.length > 0 ? styles.my_upload_label : styles.none}
                                                            >
                                                                PAN
                                                            </div>
                                                            <input
                                                                type="file"
                                                                id='customFileInput5'
                                                                accept=".pdf, .jpg, .jpeg"
                                                                required
                                                                hidden
                                                                onChange={(e) => handleFileUpload(e, 'pan')}
                                                            />
                                                            <div className={styles.file_flex}>
                                                                <label htmlFor="customFileInput5"
                                                                    className={fileNames?.pan?.length > 0 ? styles.customFileInput1_upload : styles.customFileInput1}
                                                                >
                                                                    <div className={styles.text}>
                                                                        {
                                                                            fileNames?.pan?.length > 0 ?
                                                                                (
                                                                                    truncateFileName(fileNames.pan)
                                                                                ) :
                                                                                (
                                                                                    'PAN *'
                                                                                )
                                                                        }
                                                                    </div>
                                                                    <div className={styles.image}>
                                                                        {
                                                                            fileNames?.pan?.length == 0 ? <GoUpload /> : ""
                                                                        }
                                                                    </div>
                                                                </label>
                                                                <div className={styles.image}>
                                                                    {
                                                                        fileNames?.pan !== "" ?
                                                                            (
                                                                                (
                                                                                    <div className={styles.eye_icon_container}>
                                                                                        <div
                                                                                            className={styles.icon}
                                                                                            onClick={() => setShowPanPreview(!showPanPreview)}>
                                                                                            <IoEyeOutline size={24} />
                                                                                        </div>
                                                                                        <div
                                                                                            className={styles.icon}
                                                                                            onClick={() => handleDelete('pan')}
                                                                                        >
                                                                                            <MdOutlineDeleteOutline size={24} />
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            )
                                                                            :
                                                                            (
                                                                                null
                                                                            )
                                                                    }
                                                                </div>
                                                            </div>
                                                            <Modal
                                                                open={showPanPreview}
                                                                onClose={() => setShowPanPreview(false)}
                                                                aria-labelledby="upload-address-preview-modal"
                                                                aria-describedby="upload-address-preview-description"
                                                            >
                                                                <div className={styles.preview}>
                                                                    <button
                                                                        className={styles.close_button_for_preview}
                                                                        onClick={() => setShowPanPreview(false)}
                                                                    >
                                                                        <IoCloseCircleSharp />
                                                                    </button>
                                                                    {fileNames?.pan &&
                                                                        fileNames?.pan.toLowerCase().endsWith('.pdf') ? (
                                                                        <div
                                                                            className={styles.modalImage}
                                                                        >
                                                                            {fileNames.pan.startsWith('https') ? (
                                                                                <iframe
                                                                                    type="application/pdf"
                                                                                    style={{ height: "100%", width: "100%" }}
                                                                                    src={`https://docs.google.com/gview?url=${encodeURIComponent(fileNames.pan_name)}&embedded=true`}
                                                                                />
                                                                            ) : (
                                                                                <>
                                                                                    {/* <Worker
                                                                                        workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
                                                                                    >
                                                                                        <Viewer fileUrl={imagePreviews?.pan} />
                                                                                    </Worker> */}
                                                                                    <PdfViewer pdfUrl={imagePreviews?.pan} />
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    ) : (
                                                                        <img
                                                                            className={styles.modalImage}
                                                                            src={imagePreviews?.pan || fileNames?.pan}
                                                                            alt="Upload Preview"
                                                                        />
                                                                    )}
                                                                </div>
                                                            </Modal>
                                                            {emptyFields.includes('pan') && fileNames?.pan === "" && (
                                                                <p className={styles.error}>PAN is required</p>
                                                            )}
                                                        </>

                                                        {/* BANK STATEMENT FILE INPUT */}
                                                        <>
                                                            <div
                                                                className={fileNames?.bank_statement?.length > 0 ? styles.my_upload_label : styles.none}
                                                            >
                                                                Bank Statement of Last 6 Months
                                                            </div>
                                                            <input
                                                                type="file"
                                                                id='customFileInput6'
                                                                accept=".pdf, .jpg, .jpeg"
                                                                required
                                                                hidden
                                                                onChange={(e) => handleFileUpload(e, 'bank_statement')}
                                                            />
                                                            <div className={styles.file_flex}>
                                                                <label htmlFor="customFileInput6"
                                                                    className={fileNames?.bank_statement?.length > 0 ? styles.customFileInput1_upload : styles.customFileInput1}
                                                                >
                                                                    <div className={styles.text}>
                                                                        {
                                                                            fileNames?.bank_statement?.length > 0 ?
                                                                                (
                                                                                    truncateFileName(fileNames.bank_statement)
                                                                                ) :
                                                                                (
                                                                                    'Bank Statement of Last 6 Months *'
                                                                                )
                                                                        }
                                                                    </div>
                                                                    <div className={styles.image}>
                                                                        {
                                                                            fileNames?.bank_statement?.length == 0 ? <GoUpload /> : ""
                                                                        }
                                                                    </div>
                                                                </label>
                                                                <div className={styles.image}>
                                                                    {
                                                                        fileNames?.bank_statement !== "" ?
                                                                            (
                                                                                (
                                                                                    <div className={styles.eye_icon_container}>
                                                                                        <div
                                                                                            className={styles.icon}
                                                                                            onClick={() => setShowBankStatementPreview(!showBankStatementPreview)}>
                                                                                            <IoEyeOutline size={24} />
                                                                                        </div>
                                                                                        <div
                                                                                            className={styles.icon}
                                                                                            onClick={() => handleDelete('bank_statement')}
                                                                                        >
                                                                                            <MdOutlineDeleteOutline size={24} />
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            )
                                                                            :
                                                                            (
                                                                                null
                                                                            )
                                                                    }
                                                                </div>
                                                            </div>
                                                            <Modal
                                                                open={showBankStatementPreview}
                                                                onClose={() => setShowBankStatementPreview(false)}
                                                                aria-labelledby="upload-address-preview-modal"
                                                                aria-describedby="upload-address-preview-description"
                                                            >
                                                                <div className={styles.preview}>
                                                                    <button
                                                                        className={styles.close_button_for_preview}
                                                                        onClick={() => setShowBankStatementPreview(false)}
                                                                    >
                                                                        <IoCloseCircleSharp />
                                                                    </button>
                                                                    {fileNames?.bank_statement &&
                                                                        fileNames?.bank_statement.toLowerCase().endsWith('.pdf') ? (
                                                                        <div
                                                                            className={styles.modalImage}
                                                                        >
                                                                            {fileNames.bank_statement.startsWith('https') ? (
                                                                                <iframe
                                                                                    type="application/pdf"
                                                                                    style={{ height: "100%", width: "100%" }}
                                                                                    // src={fileNames.bank_statement_name + '#toolbar=0'}
                                                                                    src={`https://docs.google.com/gview?url=${encodeURIComponent(fileNames.bank_statement_name)}&embedded=true`}
                                                                                />
                                                                            ) : (
                                                                                <>
                                                                                    {/* <Worker
                                                                                        workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
                                                                                    >
                                                                                        <Viewer fileUrl={imagePreviews?.bank_statement} />
                                                                                    </Worker> */}
                                                                                    <PdfViewer pdfUrl={imagePreviews?.bank_statement} />
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    ) : (
                                                                        <img
                                                                            className={styles.modalImage}
                                                                            src={imagePreviews?.bank_statement || fileNames?.bank_statement}
                                                                            alt="Upload Preview"
                                                                        />
                                                                    )}
                                                                </div>
                                                            </Modal>
                                                            {emptyFields.includes("bank_statement") && fileNames?.bank_statement === "" && (
                                                                <p className={styles.error}>Bank Statement is required</p>
                                                            )}
                                                        </>

                                                        {/* BANK STATEMENT PASSWORD */}
                                                        <>
                                                            <div className={styles.form} style={{ padding: "0" }}>
                                                                <div className={styles.col}>
                                                                    <div className={styles.MUI_input}>
                                                                        <input
                                                                            className={fileNames.bank_statement_password?.length > 0 ? styles.input_field1 : styles.input_field}
                                                                            type={showPassword ? "text" : "password"}
                                                                            value={fileNames.bank_statement_password}
                                                                            onChange={(e) => setFileNames({ ...fileNames, bank_statement_password: e.target.value })}
                                                                            required
                                                                        />
                                                                        <div className={`${styles.float_label} ${fileNames.bank_statement_password && styles.labelActive}`}>
                                                                            Bank Statement Password *
                                                                        </div>

                                                                        <div
                                                                            className={styles.password_icon_eye}
                                                                            onClick={() => setShowPassword(!showPassword)}
                                                                            style={{
                                                                                position: 'absolute',
                                                                                right: '10px',
                                                                                top: '50%',
                                                                                transform: 'translateY(-50%)',
                                                                                cursor: 'pointer'
                                                                            }}
                                                                        >
                                                                            {!showPassword ? <IoEye /> : <IoEyeOff />}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {emptyFields.includes("bank_statement_password") && fileNames.bank_statement_password === "" && (
                                                                <p className={styles.error}>Bank Statement Password is required</p>
                                                            )}
                                                        </>

                                                        {/* PROOF FILE INPUT FRONT */}
                                                        <>
                                                            <div
                                                                className={fileNames?.address_front?.length > 0 ? styles.my_upload_label : styles.none}
                                                            >
                                                                Upload Aadhaar Proof Front
                                                            </div>
                                                            <input
                                                                type="file"
                                                                id='customFileInput7'
                                                                accept=".pdf, .jpg, .jpeg"
                                                                required
                                                                hidden
                                                                onChange={(e) => handleFileUpload(e, 'address_front')}
                                                            />
                                                            <div className={styles.file_flex}>
                                                                <label
                                                                    htmlFor="customFileInput7"
                                                                    className={fileNames?.address_front?.length > 0 ? styles.customFileInput1_upload : styles.customFileInput1}
                                                                >
                                                                    <div className={styles.text}>
                                                                        {
                                                                            fileNames?.address_front ?
                                                                                (
                                                                                    truncateFileName(fileNames.address_front)
                                                                                ) :
                                                                                (
                                                                                    'Upload Aadhaar Proof Front *'
                                                                                )
                                                                        }
                                                                    </div>
                                                                    <div className={styles.image}>
                                                                        {
                                                                            fileNames?.address_front?.length == 0 ? <GoUpload /> : ""
                                                                        }
                                                                    </div>
                                                                </label>
                                                                <div className={styles.image}>
                                                                    {
                                                                        fileNames?.address_front != '' ?
                                                                            (
                                                                                <div className={styles.eye_icon_container}>
                                                                                    <div
                                                                                        className={styles.icon}
                                                                                        onClick={() => setShowUploadAddressPreview(!showUploadAddressPreview)}>
                                                                                        <IoEyeOutline size={24} />
                                                                                    </div>
                                                                                    <div
                                                                                        className={styles.icon}
                                                                                        onClick={() => handleDelete('address_front')}
                                                                                    >
                                                                                        <MdOutlineDeleteOutline size={24} />
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                            :
                                                                            (
                                                                                null
                                                                            )
                                                                    }
                                                                </div>
                                                            </div>
                                                            <Modal
                                                                open={showUploadAddressPreview}
                                                                onClose={() => setShowUploadAddressPreview(false)}
                                                                aria-labelledby="upload-address-preview-modal"
                                                                aria-describedby="upload-address-preview-description"
                                                            >
                                                                <div className={styles.preview}>
                                                                    <button
                                                                        className={styles.close_button_for_preview}
                                                                        onClick={() => setShowUploadAddressPreview(false)}
                                                                    >
                                                                        <IoCloseCircleSharp />
                                                                    </button>
                                                                    {fileNames?.address_front &&
                                                                        fileNames?.address_front.toLowerCase().endsWith('.pdf') ?
                                                                        (
                                                                            <div className={styles.modalImage}>
                                                                                {fileNames.address_front.startsWith('https') ? (
                                                                                    <iFrame
                                                                                        style={{ height: "100%", width: "100%", color: 'white' }}
                                                                                        type="application/pdf"
                                                                                        src={`https://docs.google.com/gview?url=${encodeURIComponent(fileNames.address_front_name)}&embedded=true`}
                                                                                    />
                                                                                ) : (
                                                                                    <>
                                                                                        {/* <Worker
                                                                                            workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
                                                                                        >
                                                                                            <Viewer fileUrl={imagePreviews?.address_front} />
                                                                                        </Worker> */}
                                                                                        <PdfViewer pdfUrl={imagePreviews?.address_front} />
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                        ) : (
                                                                            <img
                                                                                className={styles.modalImage}
                                                                                src={imagePreviews?.address_front || fileNames?.address_front}
                                                                                alt="Upload Preview"
                                                                            />
                                                                        )}
                                                                </div>
                                                            </Modal>
                                                            {emptyFields.includes("address_front") && fileNames?.address_front === "" && (
                                                                <p className={styles.error}>Upload Aadhaar is required</p>
                                                            )}
                                                        </>

                                                        {/* PROOF FILE INPUT BACK */}
                                                        <>
                                                            <div
                                                                className={fileNames?.address_back?.length > 0 ? styles.my_upload_label : styles.none}
                                                            >
                                                                Upload Aadhaar Proof Back
                                                            </div>
                                                            <input
                                                                type="file"
                                                                id='customFileInput8'
                                                                accept=".pdf, .jpg, .jpeg"
                                                                required
                                                                hidden
                                                                onChange={(e) => handleFileUpload(e, 'address_back')}
                                                            />
                                                            <div className={styles.file_flex}>
                                                                <label
                                                                    htmlFor="customFileInput8"
                                                                    className={fileNames?.address_back?.length > 0 ? styles.customFileInput1_upload : styles.customFileInput1}
                                                                >
                                                                    <div className={styles.text}>
                                                                        {
                                                                            fileNames?.address_back?.length > 0 ?
                                                                                (
                                                                                    truncateFileName(fileNames.address_back)
                                                                                ) :
                                                                                (
                                                                                    'Upload Aadhaar Proof Back *'
                                                                                )
                                                                        }
                                                                    </div>

                                                                    <div className={styles.image}>
                                                                        {
                                                                            fileNames?.address_back?.length === 0 ? <GoUpload /> : ""
                                                                        }
                                                                    </div>
                                                                </label>
                                                                <div className={styles.image}>
                                                                    {
                                                                        fileNames?.address_back !== "" ?
                                                                            (
                                                                                (
                                                                                    <div className={styles.eye_icon_container}>
                                                                                        <div
                                                                                            className={styles.icon}
                                                                                            onClick={() => setShowUploadAddressPreview1(!showUploadAddressPreview1)}>
                                                                                            <IoEyeOutline size={24} />
                                                                                        </div>
                                                                                        <div
                                                                                            className={styles.icon}
                                                                                            onClick={() => handleDelete('address_back')}
                                                                                        >
                                                                                            <MdOutlineDeleteOutline size={24} />
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            )
                                                                            :
                                                                            (
                                                                                null
                                                                            )
                                                                    }
                                                                </div>
                                                            </div>
                                                            <Modal
                                                                open={showUploadAddressPreview1}
                                                                onClose={() => setShowUploadAddressPreview1(false)}
                                                                aria-labelledby="upload-address-preview-modal"
                                                                aria-describedby="upload-address-preview-description"
                                                            >
                                                                <div className={styles.preview}>
                                                                    <button
                                                                        className={styles.close_button_for_preview}
                                                                        onClick={() => setShowUploadAddressPreview1(false)}
                                                                    >
                                                                        <IoCloseCircleSharp />
                                                                    </button>
                                                                    {fileNames?.address_back &&
                                                                        fileNames?.address_back.toLowerCase().endsWith('.pdf') ? (
                                                                        <div
                                                                            className={styles.modalImage}
                                                                        >
                                                                            {fileNames.address_back.startsWith('https') ? (
                                                                                <iframe
                                                                                    style={{ height: "100%", width: "100%" }}
                                                                                    type="application/pdf"
                                                                                    // src={fileNames.upload_address_back_name + '#toolbar=0'}

                                                                                    src={`https://docs.google.com/gview?url=${encodeURIComponent(fileNames.upload_address_back)}&embedded=true`}
                                                                                />
                                                                            ) : (
                                                                                <>
                                                                                    {/* <Worker
                                                                                        workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
                                                                                    >
                                                                                        <Viewer fileUrl={imagePreviews?.address_back} />
                                                                                    </Worker> */}
                                                                                    <PdfViewer pdfUrl={imagePreviews?.address_back} />
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    ) : (
                                                                        <img
                                                                            className={styles.modalImage}
                                                                            src={imagePreviews?.address_back || fileNames?.address_back}
                                                                            alt="Upload Preview"
                                                                        />
                                                                    )}
                                                                </div>
                                                            </Modal>
                                                            {emptyFields.includes("address_back") && fileNames?.address_back === "" && (
                                                                <p className={styles.error}>Upload Aadhaar Back is required</p>
                                                            )}
                                                        </>

                                                        {/* RC FILE INPUT FRONT */}
                                                        <>
                                                            <div
                                                                className={fileNames?.rc_front?.length > 0 ? styles.my_upload_label : styles.none}
                                                            >
                                                                RC Front
                                                            </div>
                                                            <input
                                                                type="file"
                                                                id='customFileInput9'
                                                                accept=".pdf, .jpg, .jpeg"
                                                                required
                                                                hidden
                                                                onChange={(e) => handleFileUpload(e, 'rc_front')}
                                                            />
                                                            <div className={styles.file_flex}>
                                                                <label
                                                                    htmlFor="customFileInput9"
                                                                    className={fileNames?.rc_front?.length > 0 ? styles.customFileInput1_upload : styles.customFileInput1}
                                                                >
                                                                    <div className={styles.text}>
                                                                        {
                                                                            fileNames?.rc_front ?
                                                                                (
                                                                                    truncateFileName(fileNames.rc_front)
                                                                                ) :
                                                                                (
                                                                                    'Upload RC Front *'
                                                                                )
                                                                        }
                                                                    </div>
                                                                    <div className={styles.image}>
                                                                        {
                                                                            fileNames?.rc_front?.length == 0 ? <GoUpload /> : ""
                                                                        }
                                                                    </div>
                                                                </label>
                                                                <div className={styles.image}>
                                                                    {
                                                                        fileNames?.rc_front != '' ?
                                                                            (

                                                                                <div className={styles.eye_icon_container}>

                                                                                    <div
                                                                                        className={styles.icon}
                                                                                        onClick={() => setshowRCFront(!showRCFront)}>
                                                                                        <IoEyeOutline size={24} />
                                                                                    </div>
                                                                                    <div
                                                                                        className={styles.icon}
                                                                                        onClick={() => handleDelete('rc_front')}
                                                                                    >
                                                                                        <MdOutlineDeleteOutline size={24} />
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                            :
                                                                            (
                                                                                null
                                                                            )
                                                                    }
                                                                </div>
                                                            </div>
                                                            <Modal
                                                                open={showRCFront}
                                                                onClose={() => setshowRCFront(false)}
                                                                aria-labelledby="upload-address-preview-modal"
                                                                aria-describedby="upload-address-preview-description"
                                                            >
                                                                <div className={styles.preview}>
                                                                    <button
                                                                        className={styles.close_button_for_preview}
                                                                        onClick={() => setshowRCFront(false)}
                                                                    >
                                                                        <IoCloseCircleSharp />
                                                                    </button>
                                                                    {fileNames?.rc_front &&
                                                                        fileNames?.rc_front.toLowerCase().endsWith('.pdf') ?
                                                                        (
                                                                            <div className={styles.modalImage}>
                                                                                {fileNames.address_front.startsWith('https') ? (
                                                                                    <iFrame
                                                                                        style={{ height: "100%", width: "100%", color: 'white' }}
                                                                                        type="application/pdf"
                                                                                        src={`https://docs.google.com/gview?url=${encodeURIComponent(fileNames.address_front_name)}&embedded=true`}
                                                                                    />
                                                                                ) : (
                                                                                    <>
                                                                                        {/* <Worker
                                                                                            workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
                                                                                        >
                                                                                            <Viewer fileUrl={imagePreviews?.rc_front} />
                                                                                        </Worker> */}
                                                                                        <PdfViewer pdfUrl={imagePreviews?.rc_front} />
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                        ) : (
                                                                            <img
                                                                                className={styles.modalImage}
                                                                                src={imagePreviews?.rc_front || fileNames?.rc_front}
                                                                                alt="Upload Preview rc front"
                                                                            />
                                                                        )}
                                                                </div>
                                                            </Modal>
                                                            {emptyFields.includes("rc_front") && fileNames?.rc_front === "" && (
                                                                <p className={styles.error}>RC Front is required</p>
                                                            )}
                                                        </>

                                                        {/* RC FILE INPUT BACK */}
                                                        <>
                                                            <div
                                                                className={fileNames?.rc_back?.length > 0 ? styles.my_upload_label : styles.none}
                                                            >
                                                                RC Back
                                                            </div>
                                                            <input
                                                                type="file"
                                                                id='customFileInput10'
                                                                accept=".pdf, .jpg, .jpeg"
                                                                required
                                                                hidden
                                                                onChange={(e) => handleFileUpload(e, 'rc_back')}
                                                            />
                                                            <div className={styles.file_flex}>
                                                                <label
                                                                    htmlFor="customFileInput10"
                                                                    className={fileNames?.rc_back?.length > 0 ? styles.customFileInput1_upload : styles.customFileInput1}
                                                                >
                                                                    <div className={styles.text}>
                                                                        {
                                                                            fileNames?.rc_back?.length > 0 ?
                                                                                (
                                                                                    truncateFileName(fileNames.rc_back)
                                                                                ) :
                                                                                (
                                                                                    'Upload RC Back *'
                                                                                )
                                                                        }
                                                                    </div>

                                                                    <div className={styles.image}>
                                                                        {
                                                                            fileNames?.rc_back?.length === 0 ? <GoUpload /> : ""
                                                                        }
                                                                    </div>
                                                                </label>
                                                                <div className={styles.image}>
                                                                    {
                                                                        fileNames?.rc_back !== "" ?
                                                                            (
                                                                                (
                                                                                    <div className={styles.eye_icon_container}>
                                                                                        <div
                                                                                            className={styles.icon}
                                                                                            onClick={() => setshowRCBack(!showRCBack)}>
                                                                                            <IoEyeOutline size={24} />
                                                                                        </div>
                                                                                        <div
                                                                                            className={styles.icon}
                                                                                            onClick={() => handleDelete('rc_back')}
                                                                                        >
                                                                                            <MdOutlineDeleteOutline size={24} />
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            )
                                                                            :
                                                                            (
                                                                                null
                                                                            )
                                                                    }
                                                                </div>
                                                            </div>
                                                            <Modal
                                                                open={showRCBack}
                                                                onClose={() => setshowRCBack(false)}
                                                                aria-labelledby="upload-address-preview-modal"
                                                                aria-describedby="upload-address-preview-description"
                                                            >
                                                                <div className={styles.preview}>
                                                                    <button
                                                                        className={styles.close_button_for_preview}
                                                                        onClick={() => setshowRCBack(false)}
                                                                    >
                                                                        <IoCloseCircleSharp />
                                                                    </button>
                                                                    {fileNames?.rc_back &&
                                                                        fileNames?.rc_back.toLowerCase().endsWith('.pdf') ? (
                                                                        <div
                                                                            className={styles.modalImage}
                                                                        >
                                                                            {fileNames.rc_back.startsWith('https') ? (
                                                                                <iframe
                                                                                    style={{ height: "100%", width: "100%" }}
                                                                                    type="application/pdf"
                                                                                    // src={fileNames.upload_address_back_name + '#toolbar=0'}

                                                                                    src={`https://docs.google.com/gview?url=${encodeURIComponent(fileNames.upload_address_back_name)}&embedded=true`}
                                                                                />
                                                                            ) : (
                                                                                <>
                                                                                    {/* <Worker
                                                                                        workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
                                                                                    >
                                                                                        <Viewer fileUrl={imagePreviews?.rc_back} />
                                                                                    </Worker> */}
                                                                                    <PdfViewer pdfUrl={imagePreviews?.rc_back} />
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    ) : (
                                                                        <img
                                                                            className={styles.modalImage}
                                                                            src={imagePreviews?.rc_back || fileNames?.rc_back}
                                                                            alt="Upload Preview"
                                                                        />
                                                                    )}
                                                                </div>
                                                            </Modal>
                                                            {emptyFields.includes("rc_back") && fileNames?.rc_back === "" && (
                                                                <p className={styles.error}>RC Back is required</p>
                                                            )}
                                                        </>
                                                    </>

                                                    <p style={{ marginBottom: "24px" }}>
                                                        Our team will contact you for verification please answer it
                                                    </p>
                                                </div>

                                                {/* BUTTONS CONTAINER */}
                                                <div className={styles.final_button_container}>
                                                    <button
                                                        className={styles.back_button}
                                                        type="button"
                                                        onClick={handlePrevStage}
                                                    >
                                                        Back
                                                    </button>
                                                    <button
                                                        type='submit'
                                                        onClick={handleNextStage}
                                                        className={styles.submit_button_preview}
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </>
                                        )
                                    }
                                </form>
                            </div>
                        )
                }
            </div>
        </>
    )
}

export default LoanApplication2
