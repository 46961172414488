import Marquee from 'react-fast-marquee'
import styles from './Lending.module.css'
import partner1 from '../../images/vectors/homescreen/lending_partners/lending1.svg'
import partner2 from '../../images/vectors/homescreen/lending_partners/lending2.svg'
import partner3 from '../../images/vectors/homescreen/lending_partners/lending3.svg'
import partner4 from '../../images/vectors/homescreen/lending_partners/lending4.svg'
import partner5 from '../../images/vectors/homescreen/lending_partners/lending5.svg'
import partner6 from '../../images/vectors/homescreen/lending_partners/lending6.svg'
import partner7 from '../../images/vectors/homescreen/lending_partners/kotak.svg'
import partner8 from '../../images/vectors/homescreen/lending_partners/lending8.svg'
// import partner9 from '../../images/vectors/homescreen/lending_partners/lending9.svg'

const LendingPartners = () => {
    return (
        <>
            <div className={styles.container}>
                <div className={styles.heading}>
                    Lending  Partners
                </div>

                <Marquee
                    pauseOnHover
                    pauseOnClick
                    direction='right'
                    speed={100}
                >
                    <div className={styles.image}>
                        <img src={partner1} alt="" />
                        <img src={partner2} alt="" />
                        <img src={partner3} alt="" />
                        <img src={partner4} alt="" />
                        <img src={partner5} alt="" />
                        <img src={partner6} alt="" />
                        <img src={partner7} alt="" />
                        <img src={partner8} alt="" />
                    </div>
                </Marquee>

            </div>
        </>
    )
}

export default LendingPartners