import styles from './BuyCar.module.css';
import { IoClose } from 'react-icons/io5';
import React, { useEffect, useState } from 'react'
import { Box, FormControl, InputLabel, MenuItem, Modal, Select, makeStyles } from '@material-ui/core';
import { MdKeyboardArrowDown } from "react-icons/md";
import NewLoan from '../../../loanEligibility/NewLoanEligibility/NewLoan';
import thankyou from '../../../../images/forms/sellcar/thank_you.svg'
import redirect_to_loan_form from '../../../../images/forms/buy_car/redirect_to_other_form.svg'
import axios from 'axios';
import useStyles from '../../../DropdownStyle/useStyles';

const BACKEND_URL =  `https://api.nxfin.in/uat.app`

const BuyCar = ({ handleCloseBuy }) => {
  const classes = useStyles();
  const [stage, setStage] = useState(1);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [emptyFields, setEmptyFields] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [showLoanComponent, setShowLoanComponent] = useState(false);
  const [redirectModal, setRedirectModal] = useState(false);
  const [options, setOptions] = useState([]);
  const [model, setModel] = useState([]);
  const [isInterestedInLoan, setIsInterestedInLoan] = useState(false);
  const [consentChecked, setConsentChecked] = useState(false);

  const handleClick = () => {
    if (isInterestedInLoan) {
      setRedirectModal(true);

      setTimeout(() => {
        setRedirectModal(false);
        setShowLoanComponent(true);
      }, 3000)

    }
    else {
      alert('Try Again Later...')
    }
  };

  const closeRedirectModal = () => {
    setRedirectModal(false);
  }
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const handleModelId = (id) => {
    localStorage.setItem('mymodel_id', id)
  }

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  const isValidPincode = (pincode) => {
    const pincodeRegex = /^[1-9][0-9]{5}$/;
    return pincodeRegex.test(pincode);
  };

  const handleFetchDetails = async (e) => {
    try {
      const response = await axios.get(`/pincode?pincode=${formData.pincode}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response && response.data.district) {
        setFormData((prevData) => ({
          ...prevData,
          district: response.data.district,
          city: response.data.state,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          city: 'No Data',
          district: 'No Data',
        }));
      }
    } catch (error) {
      console.log('error', error.message)
    }
  }

  const [formData, setFormData] = useState({
    full_name: '',
    email: '',
    pincode: '',
    city: '',
    district: '',
    mobile: '',

    budget_from: '',
    budget_to: '',
    car_type: '',
    car_make: '',

    how_old_car: '',
    car_has_run: '',
    car_color_preferred: '',
    car_model: '',
    consent: '0',
    loan_required: '0',
  });

  const validateStage1 = () => {
    // const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return (
      // emailPattern.test(formData.email) &&
      formData.full_name !== '' &&
      formData.pincode !== '' &&
      formData.city !== '' &&
      formData.district !== '' &&
      formData.mobile !== ''
    );
  };

  const validateStage2 = () => {
    return (
      formData.budget_from !== '' &&
      formData.budget_to !== '' &&
      formData.car_type !== '' &&
      formData.car_make !== '' &&
      formData.car_model !== '' &&
      formData.car_color_preferred !== ''
    );
  };

  const validateStage3 = () => {
    return (
      formData.how_old_car !== '' &&
      formData.car_has_run !== ''
    );
  };

  const handleNextStage = () => {
    const emptyFields = [];

    if (stage === 1 && !validateStage1()) {
      emptyFields.push("full_name", "pincode", "city", "district", "mobile");
    }

    if (stage === 2 && !validateStage2()) {
      emptyFields.push("budget_to", "budget_from", "car_type", "car_color_preferred");
    }

    if (stage === 3 && !validateStage3()) {
      emptyFields.push("how_old_car", "car_has_run");
    }

    setEmptyFields(emptyFields);

    if (emptyFields.length === 0) {
      setStage(stage + 1);
    }
  };

  const handleInputChange = (e, inputName) => {
    const newValue = e.target.files ? e.target.files[0] : e.target.value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [inputName]: newValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (consentChecked) {
        const result = await axios.post('/buy', formData)
        // console.log(result);
        setIsFormSubmitted(true);
      }
    }
    catch (error) {
      alert('please click on the consent checkbox ')
      console.log('Error in handelSubmit', error.message)
    }
  }

  const handleClose = () => {
    setShowLoanComponent(false);
  };

  const handleInterestedInLoanChange = (e) => {
    const checked = e.target.checked;
    setIsInterestedInLoan(checked);
    setFormData((prevData) => ({
      ...prevData,
      loan_required: checked ? "1" : "0",
    }));
  };

  const handleCheckBox = (e) => {
    const checked = e.target.checked;
    setConsentChecked(checked);
    setFormData((prevData) => ({
      ...prevData,
      consent: checked ? "1" : "0",
    }));
  }

  const handle_car_has_run = (fieldName, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
  };

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/make_web`);
        setOptions(response.data.make);
      } catch (error) {
        console.error('Fetch Options Error -', error.message);
      }
    };
    fetchOptions()
  }, [])

  const fetchModels = async () => {
    const mymodel_id = localStorage.getItem('mymodel_id');
    try {
      const response = await axios.get(`${BACKEND_URL}/model_web?make_id=${mymodel_id}`);
      setModel(response.data.model);
    } catch (error) {
      console.error('Fetch Models -', error.message);
    }
  }

  useEffect(() => {
    fetchModels();
  }, [localStorage.getItem('mymodel_id')]);

  const generateOptions = (startValue, endValue) => {
    const options = [];
    for (let i = startValue; i <= 9; i++) {
      options.push(
        <MenuItem key={i} value={i.toString()} className={`${classes.option}`}>
          {i}
        </MenuItem>
      );
    }
    return options;
  };


  return (
    <>
      {showLoanComponent ? (
        <NewLoan handleClose={handleClose} />
      ) : (
        <div className={styles.container}>
          {
            isFormSubmitted ? (
              <>
                <div className={styles.thank_you_image}>
                  <img src={thankyou} alt="thank_you" />
                </div>
                <div
                  onClick={handleCloseBuy}
                  className={styles.mui_close_button}
                >
                  <IoClose />
                </div>
              </>
            ) : (
              <div className={styles.form_stage}>
                <form onSubmit={handleSubmit}>

                  {stage === 1 && (
                    <>
                      {/* WELCOME TEXT */}
                      <div className={styles.heading_section}>
                        <div className={styles.welcome_text}>
                          <div className={styles.welcome_text1}>
                            Please share details of the car you plan to buy
                          </div>
                          <div className={styles.welcome_text2}>
                            Fill out your details
                          </div>
                        </div>
                        <div
                          onClick={handleCloseBuy}
                          className={styles.mui_close_button}
                        >
                          <IoClose />
                        </div>
                      </div>

                      <div className={styles.form}>
                        <div className={styles.col}>
                          <div className={styles.MUI_input}>
                            <input
                              className={formData.full_name.length > 0 ? styles.input_field1 : styles.input_field}
                              type="text"
                              value={formData.full_name}
                              onChange={(e) => handleInputChange(e, 'full_name')}
                              required
                              id='name'
                            />
                            <div className={`${styles.float_label} ${formData.full_name && styles.labelActive}`}>
                              Enter Full Name <span style={{ color: `var(--red)` }}>*</span>
                            </div>
                            {localStorage.setItem('buyer_name', formData.full_name)}
                          </div>
                          {emptyFields.includes("full_name") && formData.full_name === "" && (
                            <p className={styles.error}>Full Name is required</p>
                          )}


                          {/* MOBILE NUMBER */}
                          <div className={styles.MUI_input}>
                            <div className={`${styles.phoneNumberDisplay} ${formData.mobile && styles.phoneNumberActive}`}>
                              +91
                            </div>
                            <input
                              className={`${styles.input_field} ${formData.mobile.length > 0 ? styles.input_field2 : ''} ${isFocused ? styles.input_field2 : ''}`}
                              type="tel"
                              maxLength={10}
                              value={formData.mobile}
                              onChange={(e) => handleInputChange(e, 'mobile')}
                              required
                              onFocus={handleInputFocus}
                              onBlur={handleInputBlur}
                              id='phone_number'
                              style={{ padding: "12px 0px" }}
                            />
                            <div className={`${styles.float_label} ${formData.mobile && styles.labelActive}`}>
                              {isFocused ? "Enter your mobile number" : formData.mobile.length === 0 ? "+91 Enter your mobile number" : "Enter your mobile number"} <span style={{ color: `var(--red)` }}>*</span>
                            </div>
                            {localStorage.setItem('buyer_phone', formData.mobile)}
                          </div>
                          {emptyFields.includes("mobile") && (formData.mobile === 0 || formData.mobile === "") && (
                            <p className={styles.error}> Mobile is required</p>
                          )}
                          {formData.mobile && !/^[6789]\d{9}$/.test(formData.mobile) && (
                            <p className={styles.error}>
                              Please provide an Indian number
                            </p>
                          )}


                          <div className={styles.MUI_input}>
                            <input
                              className={formData.email.length > 0 ? styles.input_field1 : styles.input_field}
                              type="email"
                              value={formData.email}
                              onChange={(e) => handleInputChange(e, 'email')}
                              required
                              id='email'
                            />
                            <div className={`${styles.float_label} ${formData.email && styles.labelActive}`}>
                              Enter Email
                            </div>
                          </div>
                          {emptyFields.includes("email") && formData.email === "" && (
                            <p className={styles.error}>Email is required</p>
                          )}
                          {formData.email && !(emailRegex).test(formData.email) && (
                            <p className={styles.error}>
                              Please provide a valid email
                            </p>
                          )}

                          <div className={styles.MUI_input}>
                            <input
                              className={formData.pincode.length > 0 ? styles.input_field1 : styles.input_field}
                              type="tel"
                              maxLength={6}
                              minLength={6}
                              value={formData.pincode}
                              onChange={(e) => handleInputChange(e, 'pincode')}
                              onBlur={() => handleFetchDetails(formData.pincode)}
                              required
                              id='pin'
                            />
                            <div className={`${styles.float_label} ${formData.pincode && styles.labelActive}`}
                            >
                              Enter PIN Code <span style={{ color: `var(--red)` }}>*</span>
                            </div>
                            {
                              localStorage.setItem('buyer_pincode', formData.pincode)
                            }
                          </div>
                          {emptyFields.includes("pincode") && formData.pincode === "" && (
                            <p className={styles.error}>Pincode is required</p>
                          )}
                          {formData.pincode !== '' && !isValidPincode(formData.pincode) && (
                            <p className={styles.error}>Invalid PIN code</p>
                          )}



                          <div className={styles.single_address}>
                            <div className={styles.single_address_error} style={{ width: '100%' }}>
                              <div className={styles.MUI_input} style={{ width: '100%' }}>
                                <input
                                  className={formData.city.length > 0 ? styles.input_field1 : styles.input_field}
                                  style={{ width: '95%' }}
                                  type="text"
                                  value={formData.city}
                                  onChange={(e) => handleInputChange(e, 'city')}
                                  required
                                  id='state'
                                />
                                <div className={`${styles.float_label} ${formData.city && styles.labelActive}`}
                                >
                                  State <span style={{ color: `var(--red)` }}>*</span>
                                </div>
                              </div>
                              {emptyFields.includes("city") && formData.city === "" && (
                                <p className={styles.address_error}>State is required</p>
                              )}
                            </div>

                            <div className={styles.single_address_error} style={{ width: '100%' }}>
                              <div className={styles.MUI_input} style={{ width: '100%' }}>
                                <input
                                  className={formData.district.length > 0 ? styles.input_field1 : styles.input_field}
                                  style={{ width: '100%' }}
                                  type="text"
                                  value={formData.district}
                                  onChange={(e) => handleInputChange(e, 'district')}
                                  required
                                  id='city'
                                />
                                <div className={`${styles.float_label} ${formData.district && styles.labelActive}`}
                                >
                                  City <span style={{ color: `var(--red)` }}>*</span>
                                </div>
                              </div>
                              {emptyFields.includes("district") && formData.district === "" && (
                                <p className={styles.address_error} >City is required</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.final_button_container1}>
                        <button
                          className={styles.submit_button1}
                          onClick={handleNextStage}
                        >
                          Next
                        </button>
                      </div>
                    </>
                  )}

                  {stage === 2 && (
                    <>
                      <div className={styles.heading_section}>
                        <div className={styles.welcome_text}>
                          <div className={styles.welcome_text1}>
                            Please share details of the car you plan to buy
                          </div>
                          <div className={styles.welcome_text2}>
                            Fill out your details
                          </div>
                        </div>
                        <div
                          onClick={handleCloseBuy}
                          className={styles.mui_close_button}
                        >
                          <IoClose />
                        </div>
                      </div>
                      <div className={styles.form}>
                        <div className={styles.col}>

                          {/* CAR TYPE */}
                          <div className={styles.MUI_input}>
                            <div className={styles.option_container}>
                              <FormControl
                                variant="outlined"
                                fullWidth
                              >
                                <InputLabel
                                  className={classes.label}
                                  id="demo-simple-select-outlined-label"
                                >
                                  What type of car are you looking for? <span style={{ color: `var(--red)` }}>*</span>
                                </InputLabel>
                                <Select
                                  required
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  value={formData.car_type}
                                  onChange={(e) => handleInputChange(e, 'car_type')}
                                  label="What type of car are you looking for?"
                                  renderValue={(selected) => <span className={classes.selectedOption}>{selected}</span>}
                                  
                                  className={`${classes.select} ${formData.car_type.length > 0 ? classes.filled : ''}`}
                                  MenuProps={{
                                    anchorOrigin: {
                                      vertical: 'bottom',
                                      horizontal: 'left',
                                    },
                                    transformOrigin: {
                                      vertical: 'top',
                                      horizontal: 'left',
                                    },
                                    getContentAnchorEl: null,
                                    autowidth: "false",
                                  }}
                                  menulistprops={{
                                    autoFocus: true,
                                  }}
                                  onFocus={() => setIsFocused(true)}
                                  onBlur={() => setIsFocused(false)}
                                >
                                  <MenuItem
                                    value="Sedan"
                                    className={`${classes.option}`}>
                                    Sedan
                                  </MenuItem>
                                  <MenuItem
                                    value="SUV"
                                    className={`${classes.option}`}>
                                    SUV
                                  </MenuItem>
                                  <MenuItem
                                    value="Hatchback"
                                    className={`${classes.option}`}>
                                    Hatchback
                                  </MenuItem>
                                  <MenuItem
                                    value="Others"
                                    className={`${classes.option}`}>
                                    Others
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                          </div>
                          {emptyFields.includes("car_type") && formData.car_type === "" && (
                            <p className={styles.error}>Car Type is required</p>
                          )}

                          {/* CAR BUDGET */}
                          <div className={styles.MUI_input}>
                            <div className={styles.input_field_boolean}>
                              <span>What is your budget for the car ? (In Lakhs)  <span style={{ color: `var(--red)` }}>*</span></span>
                              <div className={styles.budget_container}>
                                <div className={styles.budget_from}>
                                  <FormControl
                                    variant="outlined"
                                    fullWidth
                                  >
                                    <InputLabel
                                      className={classes.label}
                                      id="demo-simple-select-outlined-label"
                                    >
                                      From
                                    </InputLabel>
                                    <Select
                                      required
                                      sx={{ "& .MuiSvgIcon-root": { color: "red" } }}
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      value={formData.budget_from}
                                      onChange={(e) => handleInputChange(e, 'budget_from')}
                                      label="From"
                                      renderValue={(selected) => <span className={classes.selectedOption}>{selected}</span>}
                                      
                                      className={`${classes.select} ${formData.budget_from.length > 0 ? classes.filled : ''}`}
                                      MenuProps={{
                                        anchorOrigin: {
                                          vertical: 'bottom',
                                          horizontal: 'left',
                                        },
                                        transformOrigin: {
                                          vertical: 'top',
                                          horizontal: 'left',
                                        },
                                        getContentAnchorEl: null,
                                        autowidth: "false",
                                      }}
                                      menulistprops={{
                                        autoFocus: true,
                                      }}
                                      onFocus={() => setIsFocused(true)}
                                      onBlur={() => setIsFocused(false)}
                                    >

                                      {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((value) => (
                                        <MenuItem key={value} value={value.toString()} className={`${classes.option}`}>
                                          {value}
                                        </MenuItem>
                                      ))}

                                    </Select>
                                  </FormControl>
                                </div>
                                <div className={styles.budget_to}>
                                  <FormControl
                                    variant="outlined"
                                    fullWidth
                                  >
                                    <InputLabel
                                      className={classes.label}
                                      id="demo-simple-select-outlined-label"
                                    >
                                      To
                                    </InputLabel>
                                    <Select
                                      required
                                      sx={{ "& .MuiSvgIcon-root": { color: "red" } }}
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      value={formData.budget_to}
                                      onChange={(e) => handleInputChange(e, 'budget_to')}
                                      label="To"
                                      renderValue={(selected) => <span className={classes.selectedOption}>{selected}</span>}
                                      
                                      className={`${classes.select} ${formData.budget_to.length > 0 ? classes.filled : ''}`}
                                      MenuProps={{
                                        anchorOrigin: {
                                          vertical: 'bottom',
                                          horizontal: 'left',
                                        },
                                        transformOrigin: {
                                          vertical: 'top',
                                          horizontal: 'left',
                                        },
                                        getContentAnchorEl: null,
                                        autowidth: "false",
                                      }}
                                      menulistprops={{
                                        autoFocus: true,
                                      }}
                                      onFocus={() => setIsFocused(true)}
                                      onBlur={() => setIsFocused(false)}
                                    >

                                      {formData.budget_from && generateOptions(formData.budget_from, 9)}

                                    </Select>
                                  </FormControl>
                                </div>
                              </div>
                            </div>
                          </div>
                          {
                            emptyFields.includes("budget_to") && formData.budget_to === "" &&
                            emptyFields.includes("budget_from") && formData.budget_from === "" &&
                            (<p className={styles.error}>Budget is required</p>)
                          }

                          {/* CAR MAKE API DROPDOWN */}
                          <div className={styles.MUI_input}>
                            <div className={styles.option_container}>
                              <FormControl
                                variant="outlined"
                                fullWidth
                              >
                                <InputLabel
                                  className={classes.label}
                                  id="demo-simple-select-outlined-label"
                                >
                                  Make <span style={{ color: `var(--red)` }}>*</span>
                                </InputLabel>
                                <Select
                                  required
                                  sx={{ "& .MuiSvgIcon-root": { color: "red" } }}
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  value={formData.car_make}
                                  onChange={(e) => handleInputChange(e, 'car_make')}
                                  label="Make"
                                  renderValue={(selected) => <span className={classes.selectedOption}>{selected}</span>}
                                  
                                  className={`${classes.select} ${formData.car_make.length > 0 ? classes.filled : ''}`}
                                  MenuProps={{
                                    anchorOrigin: {
                                      vertical: 'bottom',
                                      horizontal: 'left',
                                    },
                                    transformOrigin: {
                                      vertical: 'top',
                                      horizontal: 'left',
                                    },
                                    getContentAnchorEl: null,
                                    autowidth: "false",
                                  }}
                                  menulistprops={{
                                    autoFocus: true,
                                  }}
                                  onFocus={() => setIsFocused(true)}
                                  onBlur={() => setIsFocused(false)}
                                >

                                  {options.map((option) => (
                                    <MenuItem
                                      key={option.id}
                                      value={option.make}
                                      className={`${classes.option}`}
                                      onClick={() => handleModelId(option.id)}
                                    >
                                      {option.make}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div>
                          </div>
                          {emptyFields.includes("car_make") && formData.car_make === "" && (
                            <p className={styles.error}>Car Year's required</p>
                          )}

                          {/* CAR MODEL */}
                          {formData.car_make !== "" ?
                            (
                              <>
                                <div className={styles.MUI_input}>
                                  <div className={styles.option_container}>
                                    <FormControl
                                      variant="outlined"
                                      fullWidth
                                    >
                                      <InputLabel
                                        className={classes.label}
                                        id="demo-simple-select-outlined-label"
                                      >
                                        Model <span style={{ color: `var(--red)` }}>*</span>
                                      </InputLabel>
                                      <Select
                                        required
                                        sx={{ "& .MuiSvgIcon-root": { color: "red" } }}
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={formData.car_model}
                                        onChange={(e) => handleInputChange(e, 'car_model')}
                                        label="Model"
                                        renderValue={(selected) => <span className={classes.selectedOption}>{selected}</span>}
                                        
                                        className={`${classes.select} ${formData.car_model.length > 0 ? classes.filled : ''}`}
                                        MenuProps={{
                                          anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                          },
                                          transformOrigin: {
                                            vertical: 'top',
                                            horizontal: 'left',
                                          },
                                          getContentAnchorEl: null,
                                          autowidth: "false",
                                        }}
                                        menulistprops={{
                                          autoFocus: true,
                                        }}
                                        onFocus={() => setIsFocused(true)}
                                        onBlur={() => setIsFocused(false)}
                                      >

                                        {model.map((models) => (
                                          <MenuItem
                                            key={models.model_id}
                                            value={models.model}
                                            className={`${classes.option}`}
                                          >
                                            {models.model}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </div>
                                </div>
                                {emptyFields.includes("car_model") && formData.car_model === "" && (
                                  <p className={styles.error}> Model is required</p>
                                )}
                              </>
                            ) : ('')
                          }

                          {/* COLOR PREFERRED */}
                          <div className={styles.MUI_input}>
                            <input
                              className={formData.car_color_preferred.length > 0 ? styles.input_field1 : styles.input_field}
                              type="text"
                              value={formData.car_color_preferred}
                              onChange={(e) => handleInputChange(e, 'car_color_preferred')}
                              required
                            />
                            <div className={`${styles.float_label} ${formData.car_color_preferred && styles.labelActive}`}>
                              Color Preferred
                              {/* <span style={{ color: `var(--red)` }}>*</span> */}
                            </div>
                          </div>
                          {/* {emptyFields.includes("car_color_preferred") && formData.car_color_preferred === "" && (
                            <p className={styles.error}> Color is required</p>
                          )} */}

                        </div>
                      </div>
                      <div
                        onClick={handleNextStage}
                        className={styles.final_button_container1}>
                        <button className={styles.submit_button1}>
                          Next
                        </button>
                      </div>

                    </>
                  )}

                  {stage === 3 && (
                    <>
                      <div className={styles.heading_section}>
                        <div className={styles.welcome_text}>
                          <div className={styles.welcome_text1}>
                            Please share details of the car you plan to buy
                          </div>
                          <div className={styles.welcome_text2}>
                            Fill out your details
                          </div>
                        </div>
                        <div
                          onClick={handleCloseBuy}
                          className={styles.mui_close_button}
                        >
                          <IoClose />
                        </div>
                      </div>
                      <div className={styles.form}>
                        <div className={styles.col}>
                          {/* OLD CAR */}
                          <div className={styles.MUI_input}>
                            <div className={styles.option_container}>
                              <FormControl
                                variant="outlined"
                                fullWidth
                              >
                                <InputLabel
                                  className={classes.label}
                                  id="demo-simple-select-outlined-label"
                                >
                                  How old can the car be? (In Years) <span style={{ color: `var(--red)` }}>*</span>
                                </InputLabel>
                                <Select
                                  required
                                  sx={{ "& .MuiSvgIcon-root": { color: "red" } }}
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  value={formData.how_old_car}
                                  onChange={(e) => handleInputChange(e, 'how_old_car')}
                                  label="How old can the car be? (In Years)"
                                  renderValue={(selected) => <span className={classes.selectedOption}>{selected}</span>}
                                  
                                  className={`${classes.select} ${formData.how_old_car.length > 0 ? classes.filled : ''}`}
                                  MenuProps={{
                                    anchorOrigin: {
                                      vertical: 'bottom',
                                      horizontal: 'left',
                                    },
                                    transformOrigin: {
                                      vertical: 'top',
                                      horizontal: 'left',
                                    },
                                    getContentAnchorEl: null,
                                    autowidth: "false",
                                  }}
                                  menulistprops={{
                                    autoFocus: true,
                                  }}
                                  onFocus={() => setIsFocused(true)}
                                  onBlur={() => setIsFocused(false)}
                                >
                                  <MenuItem
                                    value="1"
                                    className={`${classes.option}`}>
                                    1
                                  </MenuItem>
                                  <MenuItem
                                    value="2"
                                    className={`${classes.option}`}>
                                    2
                                  </MenuItem>
                                  <MenuItem
                                    value="3"
                                    className={`${classes.option}`}>
                                    3
                                  </MenuItem>
                                  <MenuItem
                                    value="4"
                                    className={`${classes.option}`}>
                                    4
                                  </MenuItem>
                                  <MenuItem
                                    value="5"
                                    className={`${classes.option}`}>
                                    5
                                  </MenuItem>
                                  <MenuItem
                                    value="6"
                                    className={`${classes.option}`}>
                                    6
                                  </MenuItem>
                                  <MenuItem
                                    value="7"
                                    className={`${classes.option}`}>
                                    7
                                  </MenuItem>
                                  <MenuItem
                                    value="8"
                                    className={`${classes.option}`}>
                                    8
                                  </MenuItem>
                                  <MenuItem
                                    value="9"
                                    className={`${classes.option}`}>
                                    9
                                  </MenuItem>

                                </Select>
                              </FormControl>
                            </div>
                          </div>
                          {emptyFields.includes("how_old_car") && formData.how_old_car === "" && (
                            <p className={styles.error}>Car Year's required</p>
                          )}

                          {/* CAR HAS RUN */}
                          <div className={styles.MUI_input}>
                            <div className={styles.input_field_boolean}>
                              <span>Kilometers driven by car ? <span style={{ color: `var(--red)` }}>*</span></span>
                              <div className={styles.yes_no_container}>
                                <div
                                  className={`${styles.yes_button} ${formData.car_has_run === '0-10,000 kms' ? styles.selected : ''}`}
                                  onClick={() => handle_car_has_run('car_has_run', '0-10,000 kms')}
                                >
                                  0-10,000 kms
                                </div>
                                <div
                                  className={`${styles.yes_button} ${formData.car_has_run === '10,000-20,000 kms' ? styles.selected : ''}`}
                                  onClick={() => handle_car_has_run('car_has_run', '10,000-20,000 kms')}
                                >
                                  10,000-20,000 kms
                                </div>
                                <div
                                  className={`${styles.yes_button} ${formData.car_has_run === ' 20,000-30,000 kms' ? styles.selected : ''}`}
                                  onClick={() => handle_car_has_run('car_has_run', '20,000-30,000 kms')}
                                >
                                  20,000-30,000 kms
                                </div>
                                <div
                                  className={`${styles.yes_button} ${formData.car_has_run === '30,000-40,000kms' ? styles.selected : ''}`}
                                  onClick={() => handle_car_has_run('car_has_run', '30,000-40,000kms')}
                                >
                                  30,000-40,000kms
                                </div>
                                <div
                                  className={`${styles.yes_button} ${formData.car_has_run === '40,000-50,000kms' ? styles.selected : ''}`}
                                  onClick={() => handle_car_has_run('car_has_run', '40,000-50,000kms')}
                                >
                                  40,000-50,000kms
                                </div>
                                <div
                                  className={`${styles.yes_button} ${formData.car_has_run === '50,000+ kms' ? styles.selected : ''}`}
                                  onClick={() => handle_car_has_run('car_has_run', '50,000+ kms')}
                                >
                                  50,000+ kms
                                </div>
                              </div>
                            </div>
                          </div>
                          {emptyFields.includes("car_has_run") && formData.car_has_run === "" && (
                            <p className={styles.error}>Sole Owner is required</p>
                          )}

                          {/* LOAN INTERESTED */}
                          <div className={styles.consent_box}>
                            <div className={styles.consent_left}>
                              <input
                                type="checkbox"
                                onChange={handleInterestedInLoanChange}
                              />
                            </div>
                            <div className={styles.consent_right} >
                              Are you interested in financing your car ?
                            </div>
                          </div>

                          {/* CONSENT BOX SIMPLE */}
                          <div className={styles.consent_box}>
                            <div className={styles.consent_left}>
                              <input
                                required
                                type="checkbox"
                                onClick={handleCheckBox}
                              />
                            </div>
                            <div className={styles.consent_right}>
                              By completing and submitting this form, you acknowledge that you have read and agreed to our terms and conditions, as well as our privacy policy. Your submission constitutes consent for our team to contact you for further discussion the transaction and Financing options.
                            </div>
                          </div>

                          {
                            consentChecked && isInterestedInLoan ? (
                              <div
                                className={styles.final_button_container1}
                                onClick={handleClick}
                              >
                                <button
                                  className={styles.submit_button1}
                                  type='submit'
                                >
                                  Submit
                                </button>
                              </div>
                            ) :
                              (
                                <div
                                  className={styles.final_button_container1}>
                                  <button
                                    className={styles.submit_button1}
                                    type='submit'
                                  >
                                    Submit
                                  </button>
                                </div>
                              )
                          }
                        </div>
                      </div>
                    </>
                  )}

                </form>
              </div>
            )
          }
          {/* REDIRECT MODAL */}
          {
            redirectModal && (
              <Modal
                open={redirectModal}
                onClose={closeRedirectModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box className={styles.redirect_modal}>
                  <div className={styles.redirect_modal_container}>
                    <div
                      className={styles.close_button}
                      onClick={closeRedirectModal}
                    >
                      <IoClose />
                    </div>
                    <div className={styles.redirect_modal_image}>
                      <img src={redirect_to_loan_form} alt="redirecting_you_to_loan_eligibility_form" />
                    </div>
                    <div className={styles.redirect_modal_text}>
                      Congratulations! We are redirecting  <br />
                      you to check pre approved  <br />
                      loan offers
                    </div>
                  </div>
                </Box>
              </Modal>
            )
          }
        </div>
      )}

    </>
  )
}

export default BuyCar