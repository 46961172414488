
import React, { useEffect, useState, useRef } from 'react'
import { FormControl, InputLabel, MenuItem, Select, makeStyles } from '@material-ui/core';
import { MdDeleteOutline, MdKeyboardArrowDown, MdOutlineDeleteOutline } from "react-icons/md";
import styles from './Nxcar_form.module.css'
import img1 from '../../../images/vectors/channel_partner/partner_formo/car.svg'
import wave from '../../../images/vectors/channel_partner/partner_formo/wave.svg'
import axios from 'axios';
import { IoClose } from 'react-icons/io5';
import circle1 from '../../../images/vectors/about/hero/circles.svg'
import { Box, Modal, useMediaQuery } from '@material-ui/core';
import thank_you from '../../../images/forms/referral/thank_you.svg';
import useStyles from '../../../reusable/DropdownStyle/useStyles';

const styleMUIModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '500px',
    height: 'fitContent',
};

const styleMUIModalMobile = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: 'fitContent',
    width: '96%',
}

export default function Nxcar_form({ formFocused }) {
    const BACKEND_URL = `https://api.nxfin.in/uat.app`
    
    const isMobile = useMediaQuery('(max-width: 940px)');
    const classes = useStyles();
    const [stage, setStage] = useState(1);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [emptyFields, setEmptyFields] = useState([]);
    const [isFocused, setIsFocused] = useState(false);
    const [states, setStates] = useState([]);

    // FOR PRIORITIZE
    const formRef = useRef(null);

    useEffect(() => {
        if (formFocused) {
            formRef.current.focus();
        }
    }, [formFocused]);
    // FOR PRIORITIZE

    const handleInputFocus = () => {
        setIsFocused(true);
    };
    const handleInputBlur = () => {
        setIsFocused(false);
    };
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        state: '',
        stateID: '',
    });

    const validateStage1 = () => {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const phonePattern = /^\d{10}$/; // Assumes a 10-digit phone number format, modify as needed

        return (
            emailPattern.test(formData.email) &&
            formData.name.trim() !== '' &&
            formData.email.trim() !== '' &&
            phonePattern.test(formData.mobile.trim()) && // Validate phone number
            formData.state.trim() !== ''
        );
    };

    const fetchStatesFromAPI = async () => {
        try {
            const response = await axios.get('/stateListWeb');
            const data = response.data.state;
            if (Array.isArray(data)) {
                setStates(data);
            } else {
                console.error('Invalid data structure received from the API');
            }
        } catch (error) {
            console.error('Error fetching states:', error.message);
        }
    };


    const handleInputChange = (e, inputName) => {
        let newValue = e.target.files ? e.target.files[0] : e.target.value;

        if (e.target.tagName === 'INPUT') {
            newValue = e.target.value;
        }

        setFormData((prevFormData) => ({
            ...prevFormData,
            [inputName]: newValue,
            ...(inputName === 'state' && {
                stateID: states.find((state) => state.state === newValue)?.stateID || '',
            }),
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateStage1()) {
            setEmptyFields(["name", "email", "mobile", "state"]);
            return;
        }
        try {
            const response = await axios.post(`${BACKEND_URL}/user_web`, formData);
            setIsFormSubmitted(true);
            setFormData({
                name: '',
                email: '',
                mobile: '',
                state: '',
                stateID: '',
            });
        } catch (error) {
            if (error.response && error.response.data && error.response.data.errors) {
                setEmptyFields(error.response.data.errors);
            }
        }
    };

    useEffect(() => {
        fetchStatesFromAPI();
    }, []);

    const close_modal = () => {
        setIsFormSubmitted(false);
    }

    return (
        <>
            <div className={styles.main_container}>
                <div className={styles.container}>
                    <div className={styles.left}>
                        <div className={styles.heading}>
                            Register as Nxcar Partner 
                        </div>
                        <div className={styles.car_image}>
                            <img src={img1} alt="car_nxcar" />
                        </div>
                        <div className={styles.circle}>
                            <img src={circle1} alt="loan-form-partner" />
                        </div>
                    </div>
                    <div className={styles.right}>

                        <div className={styles.form_stage}>
                            <form onSubmit={handleSubmit}>

                                {stage === 1 && (
                                    <>
                                        {/* WELCOME TEXT */}
                                        <div className={styles.heading_section}>
                                            <div className={styles.welcome_text}>
                                                <div className={styles.welcome_text1}>
                                                    Register as Nxcar Partner
                                                </div>
                                                <div className={styles.welcome_text2}>
                                                    Fill out your details
                                                </div>
                                            </div>
                                        </div>

                                        <div className={styles.form}>
                                            <div className={styles.col}>
                                                <div className={styles.MUI_input}>
                                                    <input
                                                        ref={formRef}
                                                        className={formData.name.length > 0 ? styles.input_field1 : styles.input_field}
                                                        type="text"
                                                        value={formData.name}
                                                        onChange={(e) => handleInputChange(e, 'name')}
                                                        required
                                                    />
                                                    <div className={`${styles.float_label} ${formData.name && styles.labelActive}`}>
                                                        Enter Full Name <span style={{ color: `var(--red)` }}>*</span>
                                                    </div>
                                                    {localStorage.setItem('buyer_name', formData.name)}
                                                </div>
                                                {emptyFields.includes("name") && formData.name === "" && (
                                                    <p className={styles.error}>Full Name is required</p>
                                                )}

                                                <div className={styles.MUI_input}>
                                                    <input
                                                        className={formData.email.length > 0 ? styles.input_field1 : styles.input_field}
                                                        type="email"
                                                        value={formData.email}
                                                        onChange={(e) => handleInputChange(e, 'email')}
                                                        required
                                                    />
                                                    <div className={`${styles.float_label} ${formData.email && styles.labelActive}`}>
                                                        Enter Email
                                                    </div>
                                                </div>
                                                {emptyFields.includes("email") && formData.email === "" && (
                                                    <p className={styles.error}>Email is required</p>
                                                )}
                                                {formData.email && !(emailRegex).test(formData.email) && (
                                                    <p className={styles.error}>
                                                        Please provide a valid email
                                                    </p>
                                                )}

                                                {/* MOBILE NUMBER */}
                                                <div className={styles.MUI_input}>
                                                    <div className={`${styles.phoneNumberDisplay} ${formData.mobile && styles.phoneNumberActive}`}>
                                                        +91
                                                    </div>

                                                    <input
                                                        className={`${styles.input_field} ${formData.mobile?.length > 0 ? styles.input_field2 : ''} ${isFocused ? styles.input_field2 : ''}`}

                                                        type="tel"
                                                        maxLength={10}
                                                        value={formData.mobile}
                                                        required
                                                        onFocus={handleInputFocus}
                                                        onBlur={handleInputBlur}
                                                        id='phone_number'
                                                        style={{ padding: "12px 0px" }}
                                                        onChange={(e) => {
                                                            const inputValue = e.target.value;
                                                            const sanitizedValue = inputValue.replace(/\D/g, '');
                                                            handleInputChange({ target: { value: sanitizedValue } }, 'mobile');
                                                        }}
                                                    />
                                                    <div className={`${styles.float_label} ${formData.mobile && styles.labelActive}`}>
                                                        {isFocused ? "Enter your mobile number" : formData.mobile?.length === 0 ? "+91 Enter your mobile number" : "Enter your mobile number"} <span style={{ color: `var(--red)` }}>*</span>
                                                    </div>
                                                </div>
                                                {emptyFields.includes("mobile") && (formData.mobile.length === 0 || formData.mobile === "") && (
                                                    <p className={styles.error}> Mobile is required</p>
                                                )}
                                                {formData.mobile && !/^[6789]\d{9}$/.test(formData.mobile) && (
                                                    <p className={styles.error}>
                                                        Please provide an Indian number
                                                    </p>
                                                )}


                                                <div className={styles.MUI_input}>
                                                    <div className={styles.option_container}>
                                                        <FormControl
                                                            variant="outlined"
                                                            fullWidth
                                                        >
                                                            <InputLabel
                                                                className={classes.label}
                                                                id="demo-simple-select-outlined-label"
                                                            >
                                                                Select Your State <span style={{ color: `var(--red)` }}>*</span>
                                                            </InputLabel>
                                                            <Select
                                                                required
                                                                labelId="demo-simple-select-outlined-label"
                                                                id="demo-simple-select-outlined"
                                                                value={formData.state}
                                                                onChange={(e) => handleInputChange(e, 'state')}
                                                                label="Select Your State"
                                                                renderValue={(selected) => <span className={classes.selectedOption}>{selected}</span>}
                                                                
                                                                className={`${classes.select} ${formData.state.length > 0 ? classes.filled : ''}`}
                                                                MenuProps={{
                                                                    anchorOrigin: {
                                                                        vertical: 'bottom',
                                                                        horizontal: 'left',
                                                                    },
                                                                    transformOrigin: {
                                                                        vertical: 'top',
                                                                        horizontal: 'left',
                                                                    },
                                                                    getContentAnchorEl: null,
                                                                    autowidth: "false",
                                                                }}
                                                                menulistprops={{
                                                                    autoFocus: true,
                                                                }}
                                                                onFocus={() => setIsFocused(true)}
                                                                onBlur={() => setIsFocused(false)}
                                                            >
                                                                {states.map((state) => (
                                                                    <MenuItem
                                                                        key={state.stateID}
                                                                        value={state.state}
                                                                        className={`${classes.option}`}
                                                                    >
                                                                        {state.state}
                                                                    </MenuItem>
                                                                ))}

                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </div>
                                                {emptyFields.includes("state") && formData.state === "" && (
                                                    <p className={styles.error}>State is required</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className={styles.final_button_container1}>
                                            <button
                                                className={styles.submit_button1}
                                                type='submit'
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </>
                                )}
                            </form>
                        </div>
                    </div>
                </div>
                <div className={styles.wave}>
                    <img src={wave} alt="nxcar-wave-refer-2000-rs" />
                </div>
            </div>

            {isFormSubmitted && (
                <Modal
                    open={isFormSubmitted}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={isMobile ? styleMUIModalMobile : styleMUIModal}>
                        <div className={styles.modal_screen}>
                            <div
                                className={styles.mui_close_button2}
                                onClick={close_modal}
                            >
                                <IoClose size={24} />
                            </div>
                            <img src={thank_you} alt="nxcar-thank-you-pop-up-image" />
                            <div className={styles.modal_heading1}>
                                Application In Process
                            </div>
                            <div className={styles.modal_heading}>
                                Your application is currently being processed. We're working diligently to complete it. Thank you for choosing us!
                            </div>
                        </div>
                    </Box>
                </Modal>
            )}
        </>
    )
}
